import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CustomInput,
} from 'reactstrap';

import { withLocale } from '@dietlabs/components';
import { isToday } from 'utils/date';
import congratsIcon from 'assets/icons/hydration/congrats_icon.svg';

import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_HYDRATION } from 'view/GoogleTagManager/EventCategories';

class HydrationDayGoal extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
        goal: PropTypes.number.isRequired,
        changeGoal: PropTypes.func.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        trackEvent: PropTypes.func.isRequired,
    };

    state = {
        goal: this.props.goal,
        showModal: false,
        showModalGoalReached: false,
    };

    mililiterOptions = {
        min: 1500,
        step: 100,
        max: 3500,
    };

    ounceOptions = {
        min: 50,
        step: 5,
        max: 100,
    };

    componentDidUpdate(prevProps) {
        const { goal, value, unit } = this.props;
        const { unit: prevUnit, goal: prevGoal, value: prevValue } = prevProps;

        if (unit !== prevUnit || goal !== prevGoal || value !== prevValue) {
            if (prevValue >= prevGoal) {
                this.setGoalReached(false);
            } else if (value >= goal) {
                this.setGoalReached(true);
                this.trackEvent(`goal reached`);
            } else {
                this.setGoalReached(false);
            }

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ goal: this.props.goal });
        }
    }

    setGoalReached(val) {
        this.setState({ showModalGoalReached: val });
    }

    getOptions() {
        const { unit } = this.props;

        if (unit === 'ml') {
            return this.mililiterOptions;
        }
        if (unit === 'oz') {
            return this.ounceOptions;
        }
        throw new Error(`Unsupported unit: ${unit}`);
    }

    renderMessages() {
        return this.props.messages.map(msg => (
            <Alert key={msg} color="danger">
                {msg}
            </Alert>
        ));
    }

    toggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    };

    toggle2 = () => {
        this.setState(prevState => ({
            showModalGoalReached: !prevState.showModalGoalReached,
        }));
    };

    handleInputChange = event => {
        const valInt = parseInt(event.target.value, 10);
        this.setState({ goal: valInt });
    };

    saveGoal = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
        this.props.changeGoal(this.state.goal, this.props.unit);
        this.trackEvent(`change goal to ${this.state.goal}${this.props.unit}`);
    };

    trackEvent = action => {
        this.props.trackEvent(EVENT_CATEGORY_HYDRATION, action);
    };

    render() {
        if (!isToday(this.props.date)) {
            return null;
        }
        const { min, step, max } = this.getOptions();

        return (
            <Fragment>
                {this.renderMessages()}

                <div className="hydration-goal text-center">
                    <Button className="btn-goal" onClick={this.toggle}>
                        {this.props.t('hydration/goal-label')}{' '}
                        <span className="your-goal">
                            {Math.round(this.props.goal)} {this.props.unit}
                        </span>
                    </Button>
                    <Modal
                        className="hydration"
                        isOpen={this.state.showModal}
                        toggle={this.toggle}
                        centered
                        size="lg"
                    >
                        <ModalHeader toggle={this.toggle} />
                        <ModalBody>
                            <h2 className="text-center">
                                {this.props.t('hydration/goal-changer-label')}
                            </h2>
                            <h3 className="text-center">
                                <span className="big-number hydration">
                                    {this.state.goal}
                                </span>{' '}
                                {this.props.unit}
                            </h3>
                            <CustomInput
                                className="hydration"
                                type="range"
                                name="goal"
                                id="goal"
                                min={min}
                                max={max}
                                unit={this.props.unit}
                                step={step}
                                style={{
                                    '--value': `${((this.state.goal - min) /
                                        (max - min)) *
                                        100}%`,
                                }}
                                value={this.state.goal}
                                onChange={this.handleInputChange}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.saveGoal}>
                                {this.props.t('save')}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>

                <Modal
                    className="hydration"
                    isOpen={this.state.showModalGoalReached}
                    toggle={this.toggle2}
                    centered
                    size="lg"
                >
                    <ModalHeader toggle={this.toggle2} />
                    <ModalBody className="text-center">
                        <img
                            src={congratsIcon}
                            className="img-fluid mb-4"
                            alt=""
                        />
                        <h2 className="mb-2">
                            {this.props.t('hydration/goal-reached-label')}
                        </h2>
                        <p className="mb-0">
                            {this.props.t('hydration/goal-reached1')}
                        </p>
                        <p>{this.props.t('hydration/goal-reached2')}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle2}>
                            {this.props.t('ok')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

export { HydrationDayGoal };
export default withGTM(withLocale(HydrationDayGoal));
