import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import Loader from 'Layout/Loader';
import Header from 'Layout/Header';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import {
    Container,
    CardDeck,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    Button,
} from 'reactstrap';
import ContentPlaceholder from 'Content/Index/ContentPlaceholder';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PATH_KNOWLEDGE_ARTICLE } from '../paths';

const ARTICLES_PER_PAGE = 9;

function splitArrayIntoChunks(arr, chunkLen) {
    const chunkList = [];
    const chunkCount = Math.ceil(arr.length / chunkLen);
    for (let i = 0; i < chunkCount; i += 1) {
        chunkList.push(arr.splice(0, chunkLen));
    }
    return chunkList;
}

class ArticleList extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        articleList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        loadArticlesByCategory: PropTypes.func.isRequired,
        articlePagination: PropTypes.shape({
            pageCount: PropTypes.number.isRequired,
        }).isRequired,
    };

    state = {
        active: 'all',
        articleList: this.props.articleList,
        isLoading: false,
        pageNumber: 1,
        pageCount: this.props.articlePagination
            ? this.props.articlePagination.pageCount
            : 0,
    };

    filter = async category => {
        this.setState({
            active: category,
            isLoading: true,
            pageNumber: 1,
        });

        let request;
        if (category === 'all') {
            request = {
                pageNumber: 1,
                articlesPerPage: ARTICLES_PER_PAGE,
            };
        } else {
            request = {
                categoryIdentifier: category,
                pageNumber: 1,
                articlesPerPage: ARTICLES_PER_PAGE,
            };
        }

        const articleList = await this.props.loadArticlesByCategory(request);
        this.setState({
            articleList: articleList.data.article,
            isLoading: false,
            pageCount: articleList.data.articlePagination
                ? articleList.data.articlePagination.pageCount
                : 0,
        });
    };

    loadMore = () => {
        this.setState(
            prevState => ({
                isLoading: true,
                pageNumber: prevState.pageNumber + 1,
            }),
            async () => {
                let request;
                if (this.state.active === 'all') {
                    request = {
                        pageNumber: this.state.pageNumber,
                        articlesPerPage: ARTICLES_PER_PAGE,
                    };
                } else {
                    request = {
                        categoryIdentifier: this.state.active,
                        pageNumber: this.state.pageNumber,
                        articlesPerPage: ARTICLES_PER_PAGE,
                    };
                }

                const articleList = await this.props.loadArticlesByCategory(
                    request
                );
                this.setState(prevState => ({
                    articleList: [
                        ...prevState.articleList,
                        ...articleList.data.article,
                    ],
                    isLoading: false,
                }));
            }
        );
    };

    componentDidMount() {
        this.props.trackFirebaseEvent('ArticleList_pageview');
    }

    render() {
        // create copy of article in articlesArray instead of reference to them
        const articlesArray = Object.assign([], this.state.articleList);
        const articleChunks = splitArrayIntoChunks(articlesArray, 3);

        return (
            <Layout page="article-list">
                <Header>{this.props.t('knowledge/title')}</Header>
                <Container>
                    <div className="categoryFilter">
                        <Nav
                            pills
                            data-title={this.props.t('knowledge/show')}
                            className="justify-content-center mb-4"
                        >
                            <NavItem>
                                <NavLink
                                    active={this.state.active === 'all'}
                                    onClick={() => this.filter('all')}
                                >
                                    {this.props.t('knowledge/all')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.active === 'diet'}
                                    onClick={() => this.filter('diet')}
                                >
                                    {this.props.t('knowledge/diet')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.active === 'excercise'}
                                    onClick={() => this.filter('excercise')}
                                >
                                    {this.props.t('knowledge/excercise')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.active === 'motivation'}
                                    onClick={() => this.filter('motivation')}
                                >
                                    {this.props.t('knowledge/motivation')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={
                                        this.state.active === 'psychodietetics'
                                    }
                                    onClick={() =>
                                        this.filter('psychodietetics')
                                    }
                                >
                                    {this.props.t('knowledge/psychodietetics')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.active === 'tips'}
                                    onClick={() => this.filter('tips')}
                                >
                                    {this.props.t('knowledge/tips')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>

                    {articleChunks.map((chunk, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <CardDeck key={i}>
                            {chunk.map(article => {
                                const articleClasses = [];
                                if (article.revealType === 'diet') {
                                    articleClasses.push('reveal');
                                    articleClasses.push(
                                        `reveal-${article.revealDietPhase.name
                                            .toLowerCase()
                                            .replace(' ', '-')}`
                                    );
                                }
                                if (article.pinned) {
                                    articleClasses.push('pinned');
                                }

                                let content;

                                if (article.teaser.markdown !== '') {
                                    content = article.teaser.html.replace(
                                        /(<([^>]+)>)/gi,
                                        ''
                                    );
                                } else {
                                    content = article.lead.html.replace(
                                        /(<([^>]+)>)/gi,
                                        ''
                                    );
                                }

                                return (
                                    <Card
                                        key={article.id}
                                        tag={Link}
                                        to={`${
                                            PATH_KNOWLEDGE_ARTICLE.split(':')[0]
                                        }${article.id}-${article.slug}`}
                                        className={articleClasses.join(' ')}
                                    >
                                        <div className="thumbImg">
                                            <LazyLoadImage
                                                className="img-fluid"
                                                effect="blur"
                                                src={article.thumbUrl}
                                                alt={article.title}
                                            />
                                        </div>
                                        <CardBody>
                                            <span className="category">
                                                {article.category.name}
                                            </span>
                                            <h2>{article.title}</h2>
                                            {article.revealType === 'diet' ? (
                                                <div
                                                    className={`phase-name ${article.revealDietPhase.name
                                                        .toLowerCase()
                                                        .replace(' ', '-')}`}
                                                >
                                                    {
                                                        article.revealDietPhase
                                                            .name
                                                    }
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <p>{content}</p>
                                        </CardBody>
                                    </Card>
                                );
                            })}
                        </CardDeck>
                    ))}

                    {this.state.pageCount > this.state.pageNumber ? (
                        <section className="text-center pt-0">
                            <Button
                                color="primary"
                                onClick={() => this.loadMore()}
                            >
                                {this.props.t('knowledge/load-more')}
                            </Button>
                        </section>
                    ) : (
                        ''
                    )}
                </Container>

                {this.state.isLoading ? <Loader /> : null}
            </Layout>
        );
    }
}

export default withLoading(
    withFirebase(withLocale(ArticleList)),
    ContentPlaceholder
);
