import ArticleListPage from './ArticleList/ArticleListPage';
import ArticlePage from './Article/ArticlePage';

import { PATH_KNOWLEDGE, PATH_KNOWLEDGE_ARTICLE } from './paths';

const routes = [
    {
        path: PATH_KNOWLEDGE,
        component: ArticleListPage,
        private: false,
        exact: true,
        title: 'route-title/knowledge',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: PATH_KNOWLEDGE_ARTICLE,
        component: ArticlePage,
        private: false,
        exact: true,
        title: 'route-title/knowledge',
        mobileAppTabIdentifier: 'more',
    },
];

export { routes };
