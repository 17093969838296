import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';

export class ShoppingCartThankYouStartUsingComponent extends React.Component {
    render() {
        const { userEmail, isUserActive, isDietInPayment } = this.props;
        return (
            <React.Fragment>
                {isDietInPayment ? (
                    <section>
                        <Container>
                            <Row>
                                <Col md="12" data-test="thank-you/diet">
                                    <h2 data-test="thank-you/has-diet">
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan'
                                        )}
                                    </h2>
                                    <p>
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan/content1'
                                        )}
                                        <br />
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan/content2'
                                        )}
                                    </p>
                                    <Button
                                        color="primary"
                                        tag={Link}
                                        to={PATH_DAY_PLAN_INDEX}
                                    >
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan'
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                ) : (
                    ''
                )}

                {!isUserActive ? (
                    <section>
                        <Container>
                            <Alert
                                color="danger"
                                className="mt-5"
                                data-test="thank-you/alert"
                            >
                                {this.props.t('thank-you/warning', {
                                    email: userEmail,
                                })}
                            </Alert>
                        </Container>
                    </section>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}

ShoppingCartThankYouStartUsingComponent.propTypes = {
    t: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired,
    isUserActive: PropTypes.bool.isRequired,
    isDietInPayment: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartThankYouStartUsingComponent);
