import scrollTo from './scrollTo';

const DEFAULT_PADDING = 0;

function scrollToElement(
    element,
    {
        parent = window,
        behavior = 'smooth',
        align = 'start',
        padding = DEFAULT_PADDING,
    } = {}
) {
    if (!['start', 'center', 'end'].includes(align)) {
        throw new Error(`Unknown scroll align: '${align}'`);
    }

    if (!element) {
        return;
    }

    // Sum all offset parent’s offset top and left until given parent
    let top = element.offsetTop;
    let left = element.offsetLeft;

    // Align element to begin, center or end of the viewport
    if (align === 'start') {
        top += padding;
        left += padding;
    } else if (align === 'center') {
        top -= parent.offsetHeight / 2 - element.offsetHeight / 2;
        left -= parent.offsetWidth / 2 - element.offsetWidth / 2;
    } else if (align === 'end') {
        top += parent.offsetHeight - element.offsetHeight - padding;
        left += parent.offsetWidth - element.offsetWidth - padding;
    }

    scrollTo(parent, { left, top, behavior });
}

export default scrollToElement;
