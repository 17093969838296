import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import withConnectionType from '@dietlabs/components/src/withConnectionType';
import { withLocale } from '@dietlabs/components';

const SHOW_DELAY_MS = 5000;

class CachedDataMessageComponent extends PureComponent {
    static propTypes = {
        offLine: PropTypes.bool,
        t: PropTypes.func.isRequired,
    };

    static defaultProps = {
        offLine: undefined,
    };

    showTimeout = null;

    state = {
        hidden: true,
    };

    componentDidMount() {
        this.showTimeout = setTimeout(() => {
            this.setState({ hidden: false });
        }, SHOW_DELAY_MS);
    }

    componentWillUnmount() {
        clearTimeout(this.showTimeout);
    }

    render() {
        const { offLine, t } = this.props;

        if (!offLine) {
            return '';
        }

        return (
            <Alert
                className="cachedDataMessage"
                hidden={this.state.hidden}
                color="info"
                role="alert"
                aria-live="polite"
            >
                {t('message/showing-cached-data/network-error-offline')}
            </Alert>
        );
    }
}

export { CachedDataMessageComponent };
export default withConnectionType(withLocale(CachedDataMessageComponent));
