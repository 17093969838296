import Price from 'view/Price/Price';
import ShoppingCartDelivery from 'view/ShoppingCart/Delivery/ShoppingCartDelivery';

export default class Payment {
    constructor(
        token,
        products,
        totalPrice,
        deliveryOption,
        delivery,
        status,
        isCod
    ) {
        this.token = token || '';
        this.products = products || [];
        this.totalPrice = totalPrice || new Price(0, 'CZK');
        this.deliveryOptionPrice =
            deliveryOption && deliveryOption.price
                ? deliveryOption.price
                : new Price(0, 'CZK');
        this.saleShippingId = deliveryOption ? deliveryOption.id : null;
        this.delivery = delivery || new ShoppingCartDelivery();
        this.status = status;
        this.isCodValue = isCod || false;
    }

    get productsPrice() {
        return this.products.reduce(
            (price, product) => price.add(product.sumPrice),
            new Price(0, 'CZK')
        );
    }

    // TODO
    requiresDelivery() {
        return Boolean(this.delivery.name);
    }

    get exists() {
        return this.token && this.products.length && this.totalPrice.amount > 0;
    }

    get isAbleToProcess() {
        return this.isInit() && this.exists;
    }

    isInit() {
        return this.status === 'init';
    }

    isCod() {
        return this.isCodValue;
    }
}
