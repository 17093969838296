import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import deepClone from 'utils/deepClone';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { Redirect } from 'react-router';
import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import { validateFields } from 'view/Validation/validateFields';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import DietSettingsPlaceholder from './DietSettingsPlaceholder';
import DietSettings from './DietSettings';

import StepProvider from '../Steps/StepContext/StepProvider';
import StepActions from '../Steps/StepActions';

import StepStart from '../Steps/StepStart';
import StepSexBirthdatHeight from '../Steps/StepSexBirthdatHeight';
import StepWeight from '../Steps/StepWeight';
import StepGoal from '../Steps/StepGoal';
import StepActivityLevel from '../Steps/StepActivityLevel';
import StepStartDate from '../Steps/StepStartDate';
import StepFinish from '../Steps/StepFinish';

import dietSettingsSteps from '../dietSettingsSteps';
import { dietSettingsGetNextStep } from '../utils/NextStep/DietSettingsNextStepAction';
import { dietSettingsGetPreviousStep } from '../utils/PreviousStep/DietSettingsPreviousStepAction';
import { dietSettingsGetProgress } from '../utils/Progress/DietSettingsProgressAction';

class DietSettingsIndexContainer extends React.Component {
    weightDefaultUnit =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 'lb' : 'kg';

    heightDefaultUnit =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 'in' : 'cm';

    heightDefaultValue =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 60 : 160;

    state = {
        systemOfMeasures: this.props.dietSettings.systemOfMeasures,
        currentStep: this.props.dietSettings.diet.hadEver
            ? 'sex-birthday-height'
            : 'start',
        progress: 0,
        sex: this.props.dietSettings.sex || undefined,
        dateOfBirth: this.props.dietSettings.birthDate || undefined,
        height: {
            value: this.props.dietSettings.height
                ? this.props.dietSettings.height.value
                : this.heightDefaultValue,
            unit: this.props.dietSettings.height
                ? this.props.dietSettings.height.unit
                : this.heightDefaultUnit,
        },
        weight: {
            value: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].value
                : undefined,
            unit: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].unit
                : this.weightDefaultUnit,
        },
        dietMode: this.props.dietSettings.diet.mode || undefined,
        goalWeight: {
            value:
                (this.props.dietSettings.goalWeight || {}).value || undefined,
            unit:
                (this.props.dietSettings.goalWeight || {}).unit ||
                this.weightDefaultUnit,
        },
        activityLevel: this.props.dietSettings.diet.activityLevel || undefined,
        dietType: this.props.dietSettings.diet.dietType || undefined,
        startDate: formatIsoDate(addDays(new Date(), 2)),
        isNextButtonDisabled: false,
        errors: undefined,
        redirect: false,
        generateDietError: false,
    };

    validationRules = {
        dateOfBirth: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
        startDate: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
    };

    static propTypes = {
        dietSettings: PropTypes.shape().isRequired,
        validateData: PropTypes.func.isRequired,
        generateDiet: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.updateProgressBar(this.state.currentStep);
    }

    handleInputChange = event => {
        const fieldName = event.target.name.split('.')[0];
        const valInt = parseInt(event.target.value, 10);
        const valFloat = Math.round(parseFloat(event.target.value) * 10) / 10;

        if (fieldName === 'weight' || fieldName === 'goalWeight') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valFloat,
                },
            }));
        } else if (fieldName === 'height') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valInt,
                },
            }));
        } else if (
            (fieldName === 'dateOfBirth' || fieldName === 'startDate') &&
            event.target.value === ''
        ) {
            this.setState({
                [fieldName]: undefined,
            });
        } else {
            this.setState({ [fieldName]: event.target.value });
        }

        const validableFields = [
            'dateOfBirth',
            'weight',
            'goalWeight',
            'startDate',
        ];

        if (this.state.errors) {
            this.state.errors.details.forEach(error => {
                if (
                    (error.fieldName === fieldName ||
                        error.fieldName === `${fieldName}.value`) &&
                    validableFields.includes(fieldName)
                ) {
                    setTimeout(() => {
                        this.validateData();
                    }, 1000);
                }
            });
        }
    };

    createRequest() {
        const request = {
            sex: this.state.sex,
            dateOfBirth: this.state.dateOfBirth,
            height: this.state.height,
            weight: this.state.weight.value ? this.state.weight : undefined,
            dietMode: this.state.dietMode,
            goalWeight: this.state.goalWeight.value
                ? this.state.goalWeight
                : undefined,
            activityLevel: this.state.activityLevel,
            dietId: 1,
            startDate: this.state.startDate,
        };

        if (request.dietMode === 'stabilization') {
            request.goalWeight = deepClone(request.weight);
        }

        return request;
    }

    validateData = async () => {
        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        if (frontEndErrors.length === 0) {
            const request = this.createRequest();
            const response = await this.props.validateData(request);
            this.setState({ errors: response.data.me.validateDietSettings });
            return response.data.me.validateDietSettings;
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        return this.state.errors;
    };

    generateDiet = async () => {
        // reset generate diet error
        this.setState({ generateDietError: false });
        const request = this.createRequest();

        try {
            const response = await this.props.generateDiet(request);
            const { code } = response.data.me.dietGenerate;
            if (code === 200) {
                // set sex for trsanslator
                global.localStorage.setItem('sex', this.state.sex);
                this.setState({ redirect: true });
            } else {
                this.setState({ generateDietError: true });
                throw new Error(
                    `Failed to generate diet, got status code ${code}`
                );
            }
        } catch (e) {
            this.setState({ generateDietError: true });
            throw new Error(`Failed to generate diet, got error: ${e}`);
        }
    };

    prevStep = () => {
        const state = {
            ...this.props.dietSettings,
        };

        const prevStep = dietSettingsGetPreviousStep(
            dietSettingsSteps,
            state,
            this.state.currentStep
        );

        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'animated-fast', 'fadeOut');

        setTimeout(() => wrapper.classList.remove(...wrapper.classList), 200);
        setTimeout(() => wrapper.classList.add('hide'), 200);
        setTimeout(() => this.changeStep(prevStep), 200);
        setTimeout(() => wrapper.classList.add('animated', 'fadeIn'), 200);
    };

    nextStep = async () => {
        let errors = false;

        if (this.state.currentStep === 'sex-birthday-height') {
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (
                    error.fieldName === 'sex' ||
                    error.fieldName === 'dateOfBirth' ||
                    error.fieldName === 'height.value'
                ) {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'weight') {
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'weight.value') {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'goal') {
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'goalWeight.value') {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'start-date') {
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'startDate') {
                    errors = true;
                }
            });
        }

        if (!errors) {
            this.setState({ errors: undefined });

            const state = {
                ...this.props.dietSettings,
            };

            const nextStep = dietSettingsGetNextStep(
                dietSettingsSteps,
                state,
                this.state.currentStep
            );

            const wrapper = document.getElementById('wrapper');
            wrapper.classList.add('animated', 'animated-fast', 'fadeOut');

            setTimeout(
                () => wrapper.classList.remove(...wrapper.classList),
                200
            );
            setTimeout(() => wrapper.classList.add('hide'), 200);

            setTimeout(() => this.changeStep(nextStep), 200);
            setTimeout(() => wrapper.classList.add('animated', 'fadeIn'), 200);
        }
    };

    changeStep = nextStep => {
        scrollToTop();
        this.setState({
            currentStep: nextStep,
        });
        this.updateProgressBar(nextStep);
    };

    updateProgressBar = nextStep => {
        const state = {
            ...this.props.dietSettings,
        };

        const progress = dietSettingsGetProgress(
            dietSettingsSteps,
            state,
            nextStep
        );

        if (this.state.progress !== progress) {
            this.setState({ progress });
        }
    };

    changeNextBtnState = isDisable => {
        if (this.state.isNextButtonDisabled !== isDisable) {
            this.setState({ isNextButtonDisabled: isDisable });
        }
    };

    renderStep = currentStep => {
        switch (currentStep) {
            case 'start':
                return <StepStart name={this.props.dietSettings.name} />;
            case 'sex-birthday-height':
                return (
                    <StepSexBirthdatHeight
                        sex={this.state.sex}
                        systemOfMeasures={this.state.systemOfMeasures}
                        height={this.state.height}
                        dateOfBirth={this.state.dateOfBirth}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'weight':
                return (
                    <StepWeight
                        weight={this.state.weight}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'goal':
                return (
                    <StepGoal
                        dietMode={this.state.dietMode}
                        goalWeight={this.state.goalWeight}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'activity-level':
                return (
                    <StepActivityLevel
                        activityLevel={this.state.activityLevel}
                    />
                );
            case 'start-date':
                return (
                    <StepStartDate
                        startDate={this.state.startDate}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'finish':
                return (
                    <StepFinish
                        generateDiet={this.generateDiet}
                        generateDietError={this.state.generateDietError}
                    />
                );

            default:
                return <StepStart />;
        }
    };

    renderAction = () => (
        <StepProvider
            prevStep={() => this.prevStep}
            nextStep={() => this.nextStep}
            handleInputChange={this.handleInputChange}
            changeNextBtnState={this.changeNextBtnState}
        >
            <StepActions
                isNextButtonDisabled={this.state.isNextButtonDisabled}
                currentStep={this.state.currentStep}
            />
        </StepProvider>
    );

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                            new Date()
                        )}`,
                        state: { forceRefreshData: true },
                    }}
                    targetTab="diet"
                />
            );
        }

        return (
            <DietSettings
                progress={this.state.progress}
                renderAction={this.renderAction}
            >
                {() => (
                    <React.Fragment>
                        <StepProvider
                            prevStep={() => this.prevStep}
                            nextStep={() => this.nextStep}
                            handleInputChange={this.handleInputChange}
                            changeNextBtnState={this.changeNextBtnState}
                        >
                            <div id="wrapper" className="wrapper">
                                {this.renderStep(this.state.currentStep)}
                            </div>
                        </StepProvider>
                    </React.Fragment>
                )}
            </DietSettings>
        );
    }
}

export default withLoading(
    withLocale(DietSettingsIndexContainer),
    DietSettingsPlaceholder
);
