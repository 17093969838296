import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';
import { PATH_HYDRATION } from 'Hydration/paths';
import { PATH_KNOWLEDGE } from 'Knowledge/paths';
import { PATHS } from '../paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        path: PATH_DAY_PLAN_INDEX,
        translationKey: 'menu/diet',
    },
    {
        path: PATHS.SHOPPING_LIST,
        translationKey: 'menu/shopping-list',
    },
    {
        path: PATH_HYDRATION,
        translationKey: 'menu/hydration',
    },
    {
        path: PATH_KNOWLEDGE,
        translationKey: 'menu/knowledge',
    },
    {
        path: PATHS.CONTACT,
        translationKey: 'menu/contact',
    },
];
