export default {
    menu: 'Menu',
    'menu/home': 'Domů',
    'menu/diet': 'Dieta',
    'menu/diet-plan': 'Dietní plán',
    'menu/shop': 'Obchod',
    'menu/interventions': 'Motivace',
    'menu/knowledge': 'Oxy365',
    'menu/blog': 'Blog',
    'menu/contact': 'Kontakt',

    'menu/activities': 'Aktivity',
    'menu/shopping-list': 'Nákupní seznam',
    'menu/measurements': 'Měření',
    'menu/diet/settings': 'Nastavení diety',
    'menu/faq': 'Otázky',

    'menu/logout': 'Odhlásit se',
    'menu/login': 'Přihlásit se',
    'menu/settings': 'Nastavení',
    'menu/guides': 'Návody',
    'menu/account': 'Účet',
    'menu/psychometric-test': 'Proveďte test',

    logout: 'Odhlásit',
    save: 'Uložit',
    cancel: 'Zrušit',
    ok: 'OK',
    delete: 'Odstranit',
    add: 'Přidat',
    next: 'Další',
    prev: 'Předchozí',
    back: 'Zpět',
    print: 'Vytisknout',
    'print-header': 'Dieta OXY',
    'print-copyright1':
        'Dietní plán připravený pro {{email}}. Dietní plán chráněný autorskými právy.',
    'print-copyright2':
        'Šíření díla bez souhlasu tvůrce nebo osoby, která vlastní autorská práva k dílu, se trestá podle čl. 116 odst. o autorských právech a souvisejících právech.',
    close: 'zavřít',
    buy: 'Koupit',
    'add-field': 'Přidat pole',
    'data-saved': 'Uloženo',
    'error/message/generic': 'Něco se pokazilo',
    'error/message/network': 'Došlo k chybě sítě',
    'error/message/offline': 'Žádné připojení k síti',
    'error/message/no-account': 'Nemáte ještě účet?',
    'error/additional-message/generic':
        'Incident byl nahlášen a budeme pracovat na jeho vyřešení.',
    'error/additional-message/not-found': 'Stránka s touto adresou neexistuje.',
    'error/additional-message/user-not-found':
        'Váš účet jsme nenašli mezi registrovanými uživateli.',
    'button/refresh': 'Obnovit',
    'button/try-again': 'Zkusit znovu',
    'button/go-back': 'Zpět',
    'button/get-access': 'Získat přístup',
    'button/send-email': 'Poslat e-mailem',
    'button/retry': 'opakovat',

    week: 'týden',
    week_0: 'týden',
    week_1: '{{count}} týdny',
    week_2: '{{count}} týdnů',
    week_3: '{{count}} týdnu',

    month: 'měsíc',
    month_0: 'měsíc',
    month_1: '{{count}} měsíce',
    month_2: '{{count}} měsíců',
    month_3: '{{count}} měsíce',

    year: 'rok',
    year_0: 'rok',
    year_1: '{{count}} roky',
    year_2: '{{count}} let',
    year_3: '{{count}} roku',

    'diet-intro/title': 'Přečtěte si, než začnete',

    'diet-intro/subtitle1':
        'Gratulujeme! [Udělala jste|Udělal jste] již první krok a jste stále blíže své vysněné postavě. [Určila jste|Určil jste] si cíl a my vám ho pomůžeme dosáhnout.',
    'diet-intro/subtitle2':
        'Vyvážená a na míru šitá Dieta OXY je vynikající volbou a jednoduchým způsobem, jak dosáhnout úspěchu!',

    'diet-intro/p1/title': 'Jezte pravidelně',
    'diet-intro/p1/content':
        'Váš den je 5 vyvážených jídel. Jezte snídani nejpozději hodinu po probuzení, každé 2-3 hodiny jezte další jídlo a večeři naplánujte nejpozději 2 hodiny před spaním!',
    'diet-intro/p1/content2':
        'Nevynechávejte žádné jídlo - každé je ve Vaší dietě důležité. Díky těmto několika jednoduchým tipům můžete snadno přizpůsobit svůj jídelníček rytmu Vašeho dne.',

    'diet-intro/p2/title': 'Doplňujte tekutiny',
    'diet-intro/p2/content':
        'S jednotlivými jídly v dietě existují návrhy nápojů, které můžete konzumovat během dne. Měli byste vypít okolo 1,5-2 l tekutin denně, zejména ve formě vody a bylinných čajů. Doplňujte tekutiny malými doušky mezi jídly. Nezapomeňte na další dávku vody v případě zvýšené fyzické aktivity a v horkém počasí.',

    'diet-intro/p3/title': 'Vyměňujte jídla, která vám nechutnají',
    'diet-intro/p3/content':
        'Pokud v daný den nejste spokojeni s námi navrženým jídelníčkem, můžete jej nahradit alternativní sadou, kterou Vám nabídneme.',
    'diet-intro/p3/content2':
        'Všechna jídla, která můžete nahradit, jsou vyvážená z hlediska kalorií a živin, což zajišťuje splnění všech předpokladů Vaší diety.',

    'diet-intro/p4/title': 'Přizpůsobte pořadí jídel rytmu svého dne!',
    'diet-intro/p4/content':
        'Díky tomu, že máte pečlivě připravený dietní plán - si můžete jídlo předem naplánovat, připravit a vzít s sebou do práce nebo do školy.',
    'diet-intro/p4/content2':
        'Vyhnete se nákupu náhodných, často nezdravých jídel ve městě. Ušetříte peníze, získáte vytvarovanou postavu a zdraví. Perfektní řešení! Upravte také pořadí jídel podle svého dne - můžete například jíst jídlo z oběda v době svačiny.',

    'diet-intro/p5/title': 'Vyměňte přísady ve svých jídlech',
    'diet-intro/p5/content':
        'Pokud máte problém s nákupem přísady do svého jídla, můžete ho nahradit jiným ze seznamu náhradních jídel připravených naším dietetikem. Všechna náhradní jídla mají přesně vypočítanou základní hmotnost a živiny. Seznam náhrad pro každý produkt najdete po kliknutí na tuto ikonu: ',
    'diet-intro/p5/content2':
        'Všechna náhradní jídla mají přesně vypočítanou základní hmotnost a živiny.',

    'diet-intro/p6/title': 'Provádějte měření a sledujte své výsledky',
    'diet-intro/p6/content':
        'Kontrolujte svůj pokrok v dietě - každý, i ten nejmenší úspěch v procesu hubnutí Vás motivuje k dalšímu pokračování!',
    'diet-intro/p6/content2':
        'Náš systém Vám umožňuje pohodlně ukládat Vaše měření. Při každém měření se také můžete vyfotografovat - budete překvapeni, jak rychle se Vaše tělo mění.',

    'diet-intro/p7/title': 'Používejte nákupní seznam',
    'diet-intro/p7/content':
        'Můžete vygenerovat nákupní seznam pro dny, které si vyberete v kalendáři svého účtu - vše, co musíte udělat, je vybrat dny, které definují časový rámec, ve kterém chcete nakupovat.',
    'diet-intro/p7/content2':
        'Rozhodně to usnadňuje plánování diety a zrychluje nakupování. Seznam můžete mít u sebe v telefonu nebo si jej můžete vytisknout z počítače.',

    'diet-intro/p8/title': 'Máte další dotazy?',
    'diet-intro/p8/content': 'Podívejte se na seznam ',
    'diet-intro/p8/content2': 'nejčastěji kladených otázek',
    'diet-intro/p8/content3':
        '. Pokud máte pochybnosti týkající se diety, kontaktujte prosím našeho dietetika prostřednictvím ',
    'diet-intro/p8/content4': 'kontaktního formuláře.',

    'diet-intro/start-diet': 'Začněte s dietou',

    share: 'Sdílejte',
    OK: 'OK',
    'share/title': 'Sdílejte své pokroky',
    'share/description':
        'Sdílejte obrázek na Facebooku a / nebo Instagramu a ukažte svým přátelům, jak se Vám v dietě daří!',
    'share/how-to-read': 'Jak číst stav?',
    'share/current-diet-progress': 'den půstu',
    'share/current-diet-length': 'plánovaný počet dnů půstu',
    'share/diets-count': 'jaký půst je Váš',

    'meal/preparation-time': 'Čas',
    'diet/meal/seasoning/header': 'Koření',
    'diet/meal/optionalIngredients/header': 'Do jídla můžete také přidat',
    'meal/min': 'min.',
    'meal/how-to-prepare': 'Způsob přípravy',
    'meal/proteins-short': 'B',
    'meal/fat-short': 'T',
    'meal/carbohydrates-short': 'S',
    'meal/proteins': 'Bílkoviny',
    'meal/fat': 'Tuky',
    'meal/carbohydrates': 'Sacharidy',
    'meal/weekday0': 'Neděle',
    'meal/weekday1': 'Pondělí',
    'meal/weekday2': 'Úterý',
    'meal/weekday3': 'Středa',
    'meal/weekday4': 'Čtvrtek',
    'meal/weekday5': 'Pátek',
    'meal/weekday6': 'Sobota',
    'meal/weekday0-short': 'Ne',
    'meal/weekday1-short': 'Po',
    'meal/weekday2-short': 'Út',
    'meal/weekday3-short': 'St',
    'meal/weekday4-short': 'Čt',
    'meal/weekday5-short': 'Pá',
    'meal/weekday6-short': 'So',
    'meal/exchange-meal-button': 'Vyměňte',
    'meal/exchange-dish-button': 'Vyměňte',
    'meal/exchange-collapse': 'Sbalit',
    'meal/exchange-day-button': 'Vyměňte den',
    'meal/exchange-ingredient': 'Vyměňte přísadu',
    'meal/exchange-for': 'Můžete vyměnit na',
    'meal/original-product': 'Originální produkt',
    'meal/original-meal': 'Originální jídlo',
    'meal/original-day': 'Originální jídelníček',
    'meal/summary-title': 'Rozdělení kalorií',
    'meal/print-button': 'Vytisknout',
    'meal/print-period-alert': 'Tisk je dostupný na dobu kratší než 2 týdny.',
    'meal/show-menus-contain': 'Zobrazit jídelníčky obsahující:',
    'meal/menus-contain': 'zahrnuje:',
    'meal/search': 'Hledat',
    'meal/name-of-dish-or-ingredient': 'název jídla nebo přísady',
    'meal/there-are-no-replacements':
        'K dispozici nejsou žádné vhodné náhrady.',
    'meal/supports': 'Podporuje:',
    'next-day-notification': 'Na zítra připravte:',
    'meal/copy-day-button': 'Kopírovat na zítra',
    'meal/copy-day-button-done': 'Zkopírováno',
    'meal/replacements': 'Náhrady',
    'holiday-notification':
        'Zobrazit <u>speciální jídelníček</u> pro tento den.',
    fast: 'Dieta',
    'fast/exchange-day/alert':
        'Daná alternativa diety je k dispozici pouze po dobu 3 dnů, aby byla zachována nejrůznější možná nabídka po zbývající dobu, což umožňuje intracelulární výživu.',

    'dish/portion/you-should-have-this-dish-last':
        '[Měla byste | Měl byste] mít připravené toto jídlo předem. Pokud Vám ještě něco zbývá, nebude to již zahrnuto do Vašeho dietního plánu.',
    'dish/portion/you-should-have-this-dish':
        '[Měla byste | Měl byste] mít připravené toto jídlo předem.',

    'dish/portion/rest': 'Zbytek nebude použit ve Vašem dietním plánu.',

    'dish/portion/eat-x-of': 'Snězte jednu porci z {{total}}.',
    'dish/portion/eat-x-of_0': 'Snězte {{fraction}}&nbsp;porci z {{total}}.',
    'dish/portion/eat-x-of_1': 'Snězte {{fraction}}&nbsp;porce z {{total}}.',
    'dish/portion/eat-x-of_2': 'Snězte {{fraction}}&nbsp;porcí z {{total}}.',
    'dish/portion/eat-x-of_3': 'Snězte {{fraction}}&nbsp;porcí z {{total}}.',

    'dish/portion/eat-x-portion': 'Snězte jednu porci.',
    'dish/portion/eat-x-portion_0': 'Snězte {{fraction}}&nbsp;porci.',
    'dish/portion/eat-x-portion_1': 'Snězte {{fraction}}&nbsp;porce.',
    'dish/portion/eat-x-portion_2': 'Snězte {{fraction}}&nbsp;porcí.',
    'dish/portion/eat-x-portion_3': 'Snězte {{fraction}}&nbsp;porcí.',

    'dish/portion/x-portion-of': ' - jedna porce z {{total}}',
    'dish/portion/x-portion-of_0': ' - {{fraction}}&nbsp;porci z {{total}}',
    'dish/portion/x-portion-of_1': ' - {{fraction}}&nbsp;porce z {{total}}',
    'dish/portion/x-portion-of_2': ' - {{fraction}}&nbsp;porcí z {{total}}',
    'dish/portion/x-portion-of_3': ' - {{fraction}}&nbsp;porcí z {{total}}',

    'recipe-for-x-portion': 'Recept na jednu porci',
    'recipe-for-x-portion_0': 'Recept na {{count}} porci',
    'recipe-for-x-portion_1': 'Recept na {{count}} porce',
    'recipe-for-x-portion_2': 'Recept na {{count}} porcí',
    'recipe-for-x-portion_3': 'Recept na {{count}} porcí',

    'dish/portion/dish-used-in-following-days':
        'Toto jídlo bude použito v následujících dnech. Recept je na jednu porci.',
    'dish/portion/dish-used-in-following-days_0':
        'Toto jídlo bude použito v následujících dnech. Recept je na {{count}} porci.',
    'dish/portion/dish-used-in-following-days_1':
        'Toto jídlo bude použito v následujících dnech. Recept je na {{count}} porce.',
    'dish/portion/dish-used-in-following-days_2':
        'Toto jídlo bude použito v následujících dnech. Recept je na {{count}} porcí.',
    'dish/portion/dish-used-in-following-days_3':
        'Toto jídlo bude použito v následujících dnech. Recept je na {{count}} porcí.',

    'shopping-list/title': 'Nákupní seznam',
    'shopping-list/export-to-frisco': 'Uložit jako nákupní seznam Frisco.pl',
    'shopping-list/date-range': 'Časové období',
    'shopping-list/show-list-button': 'Vytvořte nákupní seznam',
    'shopping-list/alert/no-full-covered-by-diet-in-period':
        'Nemáte dietu na celé časové období. Nákupní seznam obsahuje produkty pouze ze dnů, kdy byla dieta sestavena.',
    'shopping-list/alert/no-diet-in-period':
        'Žádná sestavená dieta v daném časovém rozmezí.',
    'shopping-list/bought': 'Koupené',
    'shopping-list/no-products': 'Žádné produkty',

    'measurments/title': 'Vaše měření',
    'measurments/history': 'Historie',
    'measurments/current-measurments-title': 'Vaše aktuální měření',
    'measurments/edit-measurments-title': 'Upravit měření',
    'measurments/add-measurments-button': 'Přidejte měření',
    'measurments/delete-measurment-button': 'Odstraňte aktuální měření',
    'measurments/date': 'Data',
    'measurments/alert-no-measurments-in-period':
        'Žádná měření daného typu v daném časovém období',
    'measurments/param/body-weight': 'Tělesná hmotnost',
    'measurments/param/hips': 'Boky',
    'measurments/param/hip': 'Bok',
    'measurments/param/waist': 'Pas',
    'measurments/param/thighs': 'Stehna',
    'measurments/param/thigh': 'Stehno',
    'measurments/param/chest': 'Hrudník',
    'measurments/param/arm': 'Paže',
    'measurments/state/no-measurement': 'žádné měření',
    'measurments/state/change': 'změna',
    'measurments/state/no-change': 'žádná změna',

    'no-data': 'žádná data',
    'no-change': 'žádná změna',

    'redirect/diet-settings/title': 'Dietní plán',
    'redirect/diet-settings/message': 'Ještě nemáte spuštěný dietní plán.',
    'redirect/shopping-list/message':
        'Začněte s dietou, abyste viděli svůj nákupní seznam.',
    'redirect/diet-settings/button': 'Začít s dietou',

    'diet-settings/title': 'Nastavení diety',
    'diet-settings/sex': 'Pohlaví',
    'diet-settings/sex/male': 'Muž',
    'diet-settings/sex/female': 'Žena',
    'diet-settings/name': 'Jméno',
    'diet-settings/current-body-weight': 'Aktuální tělesná hmotnost:',
    'diet-settings/height': 'Výška:',
    'diet-settings/birth-date': 'Datum narození:',
    'diet-settings/your-goal': 'Váš cíl',
    'diet-settings/goal/slimming': 'Snížení tělesné hmotnosti',
    'diet-settings/goal/stabilization': 'Udržení tělesné hmotnosti',
    'diet-settings/goal/put-on-weight': 'Zvýšení tělesné hmotnosti',
    'diet-settings/goal/gain-muscle-mass': 'Budování svalové hmoty',
    'diet-settings/goal-weight': 'Cílová tělesná hmotnost',
    'diet-settings/diet-type/vege': 'Vege dieta',
    'diet-settings/diet-type/vege/description':
        'Nezahrnuje maso, ryby a mořské plody, pšenici a její deriváty a kravské mléko. Místo toho zahrnuje sýr, fermentované mléčné výrobky, vejce a luštěniny.',
    'diet-settings/activity/question': 'Kolikrát v průměru týdně cvičíte?',
    'diet-settings/activity/none':
        'cvičím sporadicky nebo vůbec - kancelářská práce',
    'diet-settings/activity/low': 'cvičím 1-3 hodiny týdně',
    'diet-settings/activity/medium': 'cvičím 3-5 hodin týdně (silová cvičení)',
    'diet-settings/activity/high':
        'cvičím 3-5 hodin týdně (aerobní cvičení, běh, spinning atd.)',
    'diet-settings/final/text':
        'Co je třeba vzít v úvahu při zahájení nebo změně dietního plánu?',
    'diet-settings/final/list/1': 'naplánovat čas na nákupy potřebné pro dietu',
    'diet-settings/final/list/2':
        'pokud plánujete výlet, který Vám bude bránit v dodržování jídelníčku, odložte začátek diety na vhodnější datum.',
    'diet-settings/final/list/3':
        'neodkládejte datum zahájení diety - protože jste již udělali první krok, pokračujte',
    'diet-settings/final/list/4': 'na zahájení diety máte 15 dní',
    'diet-settings/final/start-date-question':
        'Kdy chcete začít s dietou nebo v ní udělat změny?',
    'diet-settings/welcome-user': 'Dobrý den',
    'diet-settings/welcome-message':
        'Odpovězte na několik otázek, které nám pomohou připravit dietní plán, který pro Vás bude nejvhodnější.',
    'diet-settings/cta': 'Začněte',
    'diet-settings/acceptance/title': 'Dodržování diety',
    'diet-settings/acceptance/msg1':
        'Dietu byste neměli držet v případě strachu z omezení jídla, užívání steroidních hormonů a cytostatik, stejně jako selhání nadledvinek, těžké deprese, stavů po transplantacích orgánů, podstoupení chemoterapie, cirhózy v konečném stadiu, dialýzy ledvin, hypertyreózy, cukrovky typu I, akutní porfyrie , těhotenství, nezletilých osob a během kojení.',
    'diet-settings/acceptance/msg2':
        'V případě užívání léků, cukrovky typu 2, onemocnění ledvin, onemocnění jater, onemocnění štítné žlázy, žaludečních a střevních vředů, ledvinových kamenů, zvýšené laboratorní hodnoty: jaterních testů (ALAT, AST), kreatininu, nutričních nedostatků, anémie, nemoci související s dietou a dalších chronických a akutních onemocnění by měla být dieta držena se souhlasem a pod dohledem lékaře.',
    'diet-settings/acceptance/msg3':
        'Doporučení obsažená v aplikaci slouží pouze pro informační účely a nepředstavují lékařskou pomoc a jejich použití v praxi nenahradí individuální lékařskou návštěvu u odborného lékaře nebo dietetika.',
    'diet-settings/acceptance/acceptance':
        'Prohlašuji, že jsem si přečetl (a) kontraindikace a že výše uvedenými chorobami netrpím nebo že budu se souhlasem a pod dohledem lékaře používat dietu.',
    'diet-settings/acceptance/confirm': 'Potvrzuji',
    'diet-settings/diet-programme/label': 'Vyberte rozsah diety:',
    'diet-settings/diet-programme/full/title': 'Kompletní výživový program',
    'diet-settings/diet-programme/full/description':
        'Zahrnuje: Příprava na půst, Půst, Konec půstu a Zdravé stravování',
    'diet-settings/diet-programme/fast-preparation/title': 'Příprava na půst',
    'diet-settings/diet-programme/fast-preparation/description':
        'Jednotlivá fáze',
    'diet-settings/diet-programme/fast/title': 'Půst',
    'diet-settings/diet-programme/fast/description': 'Jednotlivá fáze',
    'diet-settings/diet-programme/fast-exit/title': 'Konec půstu',
    'diet-settings/diet-programme/fast-exit/description': 'Jednotlivá fáze',
    'diet-settings/diet-programme/healthy-eating/title': 'Zdravé stravování',
    'diet-settings/diet-programme/healthy-eating/description':
        'Jednotlivá fáze',
    'diet-settings/fast-days/title': 'Nastavte dobu trvání fáze:',
    'diet-settings/fast-days/fast-preparation/title': 'Příprava na půst',
    'diet-settings/fast-days/fast-preparation/info':
        'Pro správnou přípravu na půst by mělo toto období trvat nejméně 3 dny',
    'diet-settings/fast-days/fast/title': 'Půst',
    'diet-settings/fast-days/fast/info':
        'Navrhované období půstu je minimálně 7 dnů',
    'diet-settings/fast-days/days': 'dnů',
    'diet-settings/diet-type/label': 'Jaký typ diety chcete dodržovat?',
    'diet-settings/diet-type/label/stage': 'Zdravé stravování',
    'diet-settings/diet-type/mediterranean-diet/title': 'Středomořská dieta',
    'diet-settings/diet-type/mediterranean-diet/description':
        'Založena na zelenině a ovoci, obsahující celozrnné produkty a zdravé tuky, omezující spotřebu masa a mléčných výrobků.',
    'diet-settings/diet-type/elimination-diet/title': 'Eliminační dieta',
    'diet-settings/diet-type/elimination-diet/description':
        'Dieta bez lepku, mléka a mléčných výrobků a vajec.',
    'diet-settings/fast-day-of-the-week/label':
        'Vyberte den v týdnu, kdy chcete držet jednodenní půst:',
    'diet-settings/fast-day-of-the-week/mon': 'Po',
    'diet-settings/fast-day-of-the-week/tue': 'Út',
    'diet-settings/fast-day-of-the-week/wed': 'St',
    'diet-settings/fast-day-of-the-week/thur': 'Čt',
    'diet-settings/fast-day-of-the-week/fri': 'Pá',
    'diet-settings/fast-day-of-the-week/sat': 'So',
    'diet-settings/fast-day-of-the-week/sun': 'Ne',
    'diet-settings/fast-day-of-the-week/info':
        'Svou volbu můžete později změnit v nastavení.',
    'diet-settings/sex/label': 'Vaše pohlaví:',
    'diet-settings/goal/label': 'Cíl držení diety',
    'diet-settings/work-type/label':
        'Jak je úroveň Vašeho pohybu během dne? (netýká se tréninků)',
    'diet-settings/work-type/low/title': 'Nízká',
    'diet-settings/work-type/low/description':
        'práce z domova, cestování pouze autem',
    'diet-settings/work-type/medium/title': 'Střední',
    'diet-settings/work-type/medium/description':
        'práce vsedě, různé druhy dopravy',
    'diet-settings/work-type/high/title': 'Vysoká',
    'diet-settings/work-type/high/description':
        'fyzická práce, více chodím než jezdím',
    'diet-settings/activity-level/label': 'Kolikrát týdně cvičíte?',
    'diet-settings/activity-level/none': 'Cvičím méně než 1 den v týdnu',
    'diet-settings/activity-level/low': 'Cvičím 1-3 dny v týdnu',
    'diet-settings/activity-level/medium': 'Cvičím 4-5 dní v týdnu',
    'diet-settings/activity-level/high': 'Cvičím 6-7 dní v týdnu',
    'diet-settings/finish':
        'Děkujeme. Naši dietologové sestavují Váš osobní dietní plán.',
    'diet-settings/diet-id/label': 'Druh diety',
    'diet-settings/diet-type/oxy-simple/title': 'Dieta OXY Simple',
    'diet-settings/diet-type/oxy-protein/title': 'Bílkovinová Dieta OXY',
    'diet-settings/diet-type/oxy-simple/description':
        'Bílkoviny pocházejí z masa, ryb, mléčných výrobků, vajec, rostlinných produktů.',
    'diet-settings/diet-type/oxy-simple-vege/title': 'Dieta OXY Simple Vege',
    'diet-settings/diet-type/oxy-simple-vege/description':
        'Nezahrnuje maso. Bílkoviny pocházejí z mléčných výrobků, vajec a rostlinných produktů.',
    'diet-settings/diet-type/oxy/title': 'Dieta OXY',
    'diet-settings/diet-type/oxy/description':
        'Bílkoviny pocházejí z masa, ryb, mléčných výrobků, vajec, rostlinných produktů.',
    'diet-settings/diet-type/oxy-vege/title': 'Dieta OXY Vege',
    'diet-settings/diet-type/oxy-vege/description':
        'Nezahrnuje maso. Bílkoviny pocházejí z mléčných výrobků, vajec a rostlinných produktů.',
    'diet-settings/diet-type/oxy-vege-fish/title': 'Dieta OXY Vege + Fish',
    'diet-settings/diet-type/oxy-vege-fish/description':
        'Zahrnuje ryby a mořské plody. Bílkoviny také pocházejí z mléčných výrobků, vajec a rostlinných produktů.',
    'diet-settings/diet-type/fast-and-mixed/title':
        'Rychlá a tradiční příchutě',
    'diet-settings/diet-type/fast-and-mixed/description':
        'jídla jsou rychlá a jednoduchá, můžete si je připravit ze známých, obecně dostupných surovin, které si můžete koupit ve svých oblíbených obchodech',
    'diet-settings/diet-type/fast-and-traditional/title':
        'Rychlá a chutě světa',
    'diet-settings/diet-type/fast-and-traditional/description':
        'jídla připravíte rychle, návrhy jídel jsou originálnější a pestřejší, objevuje se více nových chutí z celého světa',
    'diet-settings/diet-type/engaging-and-mixed/title':
        'Poutavé a tradiční chutě',
    'diet-settings/diet-type/engaging-and-mixed/description':
        'jídla jsou založena na známých a osvědčených přísadách, ale vyžadují delší dobu přípravy',
    'diet-settings/diet-type/engaging-and-traditional/title':
        'Poutavá a chutě světa',
    'diet-settings/diet-type/engaging-and-traditional/description':
        'jídla se sofistikovanějšími a originálními jídly, potřebujete na jejich přípravu trochu více času',

    'settings/title': 'Nastavení',
    'settings/profile': 'Profil uživatele',
    'settings/email': 'Emailová adresa',
    'settings/metric-system': 'Metrický systém',
    'settings/si': 'Jednotky SI (kg, cm)',
    'settings/imperial': 'Imperiální jednotky (palce, libry)',
    'settings/notifications': 'Oznámení',
    'settings/agreements/diet-course': 'Týkající se průběhu diety',
    'settings/agreements/articles': 'Motivace a články',
    'settings/agreements/newsletter': 'Newsletter',
    'settings/agreements/newsletter-info':
        'Odhlášení z newsletteru může trvat až 2 hodiny.',
    'settings/change-password': 'Změnit heslo',
    'settings/change-password/current-password': 'Aktuální heslo',
    'settings/change-password/new-password': 'Nové heslo',
    'settings/change-password/success': 'Heslo bylo změněno',
    'settings/products/access/end-date {date}': 'Přístup zaplacený do {date}.',
    'settings/products/access/past-end-date {date}': 'Přístup vypršel {date}.',
    'settings/products/access/from-subscription':
        'Přístup na základě předplatného.',
    'settings/products/access/lifetime': 'Celoživotní přístup.',
    'settings/products/access/none': 'Žádný.',
    'settings/products/diet/heading': 'Dieta',
    'settings/products/heading': 'Aktivní produkty',
    'settings/subscriptions/cancellation-instructions': 'Pokyny ke zrušení',
    'settings/subscriptions/heading': 'Aktivní předplatné',
    'settings/subscriptions/diet/heading': 'Dieta',
    'settings/subscriptions/none': 'Žádný.',
    'settings/subscriptions/renewal-date {date}': 'Další obnovení: {date}.',
    'settings/subscriptions/renewal-date/in-past': 'Probíhá obnovení.',
    'settings/subscriptions/type/diets': 'Přístup do diety',
    'settings/hydration': 'Hydratace',
    'settings/hydration/daily-goal': 'Denní cíl',
    'settings/hydration/success': 'Cíl byl změněn',
    'settings/hydration/notifications': 'Připomenutí o pití vody',
    'settings/hydration/notifications/hour-interval': 'Jaký hodinový interval',
    'settings/hydration/notifications/from-hour': 'Více než hodinu',
    'settings/hydration/notifications/to-hour': 'Do hodiny',
    'settings/hydration/notifications/use-sound': 'Se zvukem',
    'settings/remove-account': 'Mazání účtů',

    'remove-account/title': 'Mazání účtů',
    'remove-account/app-store/disclaimer':
        '<b>Pozornost! Smazáním účtu se nezruší předplatné.</b> Aktivní předplatná můžete spravovat v nastavení účtu Apple ID na iPhonu.',
    'remove-account/google-play/disclaimer':
        '<b>Pozornost! Smazáním účtu se nezruší předplatné.</b> Aktivní předplatná můžete spravovat v nastavení aplikace Google Play.',
    'remove-account/email-confirm-info':
        'Proces smazání účtu vyžaduje ověření vašeho účtu. Za tímto účelem vám zašleme e-mail na následující adresu:  {{userEmail}}',
    'remove-account/message-sent': 'Zpráva odeslána',
    'remove-account/link-expire-info': 'Platnost odkazu vyprší po 24 hodinách.',
    'remove-account/check-your-mailbox':
        'Zkontrolujte svou e-mailovou schránku. Klikněte na odkaz ve zprávě, kterou jsme vám poslali. <br /> <b>Váš účet nebude smazán, dokud tak neučiníte.</b>',
    'remove-account/confirmation':
        'Potvrzuji, chci smazat svůj účet z aplikace.',
    'remove-account/info-title': 'Po smazání účtu:',
    'remove-account/info_1':
        'nepřihlásíte se ke svému účtu, tím ztratíte přístup k aplikaci',
    'remove-account/info_2':
        'ztratíte veškerý svůj pokrok, historii, odměny atd. za trénink, dietu a další aktivity v aplikaci',
    'remove-account/info_3': 'data vašeho účtu budou nenávratně smazána',
    'remove-account/error': 'Vyskytla se chyba',
    'remove-account/try-in-moment': 'Zkuste to prosím znovu za chvíli.',
    'remove-account/link-expired': 'Platnost odkazu vypršela.',
    'remove-account/link-expired-info':
        'Zopakujte proces smazání účtu v aplikaci, abyste dostali nový e-mail.',
    'remove-account/success': 'Váš účet byl smazán',
    'remove-account/success-info':
        'Za chvíli budete z aplikace automaticky odhlášeni.',

    'footer/oxy365': 'Blog Dieta OXY',
    'footer/service-access': 'Přístup ke službě a platbě',
    'footer/tos': 'Podmínky',
    'footer/privacy-policy': 'Zásady ochrany osobních údajů',
    'footer/contact': 'Kontakt',
    'footer/faq': 'Otázky a odpovědi',
    'footer/secure-payments': 'Bezpečné platby zajišťuje',
    'footer/follow-us': 'Sledujte nás na',
    'button/buy-diet': 'Kupte si přístup do diety',
    'button/buy-subscribtion': 'Kupte si předplatné',
    'button/buy': 'Koupit',
    'button/remove-account': 'Smazat účet',

    'error/unauthorized/subscription/expired/title':
        'Platnost Vašeho předplatného vypršela\n{{accessTo}}',
    'error/unauthorized/subscription/expired/content':
        'Pokračujte v dietě využitím nabídky pro stálé zákazníky.',
    'error/unauthorized/subscription/never-had/title':
        'Začněte s dietou hned teď!',
    'error/unauthorized/subscription/never-had/content':
        'Jste jen jedno rozhodnutí od zahájení Diety OXY. Neztrácejte čas, když cíl je na dosah!',
    'error/unauthorized/subscription/refresh': 'Zkontrolovat znovu',
    'error/unauthorized/subscription/restore-purchuases':
        'Přiřaďte zakoupené předplatné',

    'info-pages/goal-reached/title':
        'Bravo! [Dosáhla jste|Dosáhl jste] svého cíle',
    'info-pages/goal-reached/content':
        'Váš cíl byl dosažen! Je čas se rozhodnout, co chcete dělat dál.',
    'info-pages/goal-reached/button': 'Upravte svůj dietní plán',

    'info-pages/goal-lost/title': 'Bohužel! Váš cíl nebyl dosažen.',
    'info-pages/goal-lost/content':
        'Pokud se chcete vrátit k cílové hmotnosti, doporučujeme přejít na zeštíhlující dietu. ',
    'info-pages/goal-lost/button': 'Změňte svůj dietní plán',

    'info-pages/current-weight-needed/title': 'Přidejte aktuální měření',
    'info-pages/current-weight-needed/content':
        'Chcete-li připravit další dietní plán, musíte přidat aktuální měření hmotnosti.',
    'info-pages/current-weight-needed/button': 'Přidejte měření',

    'info-pages/diet-available-in-future/title':
        'Vaše dieta bude k dispozici za {{count}} dní',
    'info-pages/diet-available-in-future/title_0':
        'Vaše dieta bude k dispozici za {{count}} den',
    'info-pages/diet-available-in-future/title_1':
        'Vaše dieta bude k dispozici za {{count}} dní',
    'info-pages/diet-available-in-future/title_2':
        'Vaše dieta bude k dispozici za {{count}} dní',
    'info-pages/diet-available-in-future/title_3':
        'Vaše dieta bude k dispozici za {{count}} dne',
    'info-pages/diet-available-in-future/content':
        'Nový dietní plán lze připravit nejdříve 7 dní před začátkem. Během této doby nezapomeňte provést aktuální měření hmotnosti.',

    'info-pages/diet-outside-access/title':
        'Vaše předplatné končí za jeden den, {{date}}',
    'info-pages/diet-outside-access/title_0':
        'Vaše předplatné končí za {{count}} den, {{date}}',
    'info-pages/diet-outside-access/title_1':
        'Vaše předplatné končí za {{count}} dní, {{date}}',
    'info-pages/diet-outside-access/title_2':
        'Vaše předplatné končí za {{count}} dní, {{date}}',
    'info-pages/diet-outside-access/title_3':
        'Vaše předplatné končí za {{count}} dne, {{date}}',
    'info-pages/diet-outside-access/unknown-number': 'není známo kolik',

    'info-pages/diet-outside-access/web-title':
        'Vaše předplatné končí za jeden den, {{date}}',
    'info-pages/diet-outside-access/web-title_0':
        'Vaše předplatné končí za {{count}} den, {{date}}',
    'info-pages/diet-outside-access/web-title_1':
        'Vaše předplatné končí za {{count}} dní, {{date}}',
    'info-pages/diet-outside-access/web-title_2':
        'Vaše předplatné končí za {{count}} dní, {{date}}',
    'info-pages/diet-outside-access/web-title_3':
        'Vaše předplatné končí za {{count}} dne, {{date}}',

    'info-pages/diet-completed/now':
        '[Skončila jste|Skončil jste] {{phase}}. Vyberte, co chcete dělat dál.',
    'info-pages/diet-completed/in-future':
        '{{phase}} končí za {{count}} den. Již nyní si můžete vybrat, co chcete dělat dál.',
    'info-pages/diet-completed/in-future_0':
        '{{phase}} končí za {{count}} den. Již nyní si můžete vybrat, co chcete dělat dál.',
    'info-pages/diet-completed/in-future_1':
        '{{phase}} končí za {{count}} dní. Již nyní si můžete vybrat, co chcete dělat dál.',
    'info-pages/diet-completed/in-future_2':
        '{{phase}} končí za {{count}} dní. Již nyní si můžete vybrat, co chcete dělat dál.',
    'info-pages/diet-completed/in-future_3':
        '{{phase}} končí za {{count}} dne. Již nyní si můžete vybrat, co chcete dělat dál.',

    'info-pages/after-long-break':
        'Nebyl/a jste s námi dlouho. [Skončila jste|Skončil jste] {{phase}}. Vyberte, co chcete dělat dál.',

    'contact/title': 'Kontakt',
    'contact/alert':
        'Než nás budete kontaktovat, ujistěte se, že odpověď na Vaši otázku není v často kladených dotazech.',
    'contact/faq': 'Často kladené otázky',
    'contact/contact-form': 'Kontaktní formulář',
    'contact/name-surname': 'Jméno a příjmení',
    'contact/email-address': 'Vaše emailová adresa',
    'contact/topic': 'Předmět zprávy',
    'contact/topic/topic1': 'Problém s nákupem dietního plánu',
    'contact/topic/topic2': 'Otázka pro dietologa',
    'contact/topic/topic4': 'Problém s přístupem na web',
    'contact/topic/topic5': 'Dietní plán - reklamace a vrácení',
    'contact/topic/topic6': 'Internetový obchod - reklamace a vrácení',
    'contact/topic/topic7': 'Spolupráce',
    'contact/message': 'Zpráva',
    'contact/send-message': 'Poslat zprávu',
    'contact/send-success': 'Zpráva byla odeslána. Děkujeme.',

    'knowledge/title': 'Oxy365',
    'knowledge/show': 'Ukázat:',
    'knowledge/all': 'Všechno',
    'knowledge/diet': 'Dieta',
    'knowledge/excercise': 'Cvičení',
    'knowledge/motivation': 'Motivace',
    'knowledge/psychodietetics': 'Psychodietetika',
    'knowledge/tips': 'Tipy',
    'knowledge/back-to-article-list': 'Zpět na seznam článků',
    'knowledge/no-access':
        'Plné znění článku je k dispozici pouze uživatelům s aktivním dietním plánem.',
    'knowledge/author': 'Autor',
    'knowledge/about-author-male': 'O autorovi',
    'knowledge/about-author-female': 'O autorce',
    'me/account/activate/message': 'Váš účet byl aktivován.',
    'knowledge/load-more': 'Načíst další',

    'make-decision/title': 'Rozhodněte se',
    'make-decision/start': 'Start',
    'make-decision/now': 'Nyní',
    'make-decision/goal': 'Cíl',
    'make-decision/left': 'Zůstalo',
    'make-decision/put-on-weight': 'Přibírání na váze',
    'make-decision/gain-muscle-mass': 'Budování svalové hmoty',
    'make-decision/weight-loose': 'Hubnutí',
    'make-decision/stabilization': 'Stabilizace',
    'make-decision/continue-slimming':
        'Pokud chcete zhubnout více kilogramů, zadejte svůj novou cílovou hmotnost.',
    'make-decision/change-diet-mode-from-slimming-to-stabilization':
        'Dosáhli jste cílové hmotnosti. Chcete-li tyto efekty zachovat, vyberte počáteční datum stabilizační fáze.',
    'make-decision/continue-stabilization':
        'Pokud i přes změnu hmotnosti stále chcete používat stabilizační dietu, klikněte na tlačítko níže. Upozorňujeme, že výběrem této možnosti nastavíte poslední hodnotu přidaného měření hmotnosti jako Vaši aktuální cílovou hmotnost.',
    'make-decision/change-diet-mode-from-stabilization-to-slimming':
        'Protože se Vaše tělesná hmotnost zvýšila, doporučujeme Vám přejít na zeštíhlující dietu Zadejte novou cílovou hmotnost a zvolte, kdy chcete s dietou začít.',
    'make-decision/change-diet-mode-from-stabilization-to-put-on-weight':
        'Protože se Vaše tělesná hmotnost snížila, doporučujeme Vám přejít na dietu pro přibírání na váze. Zadejte novou cílovou hmotnost a zvolte, kdy chcete s dietou začít.',
    'make-decision/continue-put-on-weight':
        'Chcete-li pokračovat ve své dietě pro přibírání na váze, zadejte novou cílovou hmotnost.',
    'make-decision/change-diet-mode-from-put-on-weight-to-stabilization':
        'Dosáhli jste cílové hmotnosti. Chcete-li tyto efekty zachovat, vyberte počáteční datum stabilizační fáze. ',
    'make-decision/continue-gain-muscle-mass':
        'Pokud chcete pokračovat v budování svalové hmoty, vyberte novou cílovou hmotnost.',
    'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization':
        'Dosáhli jste cílové hmotnosti. Chcete-li tyto efekty zachovat, vyberte počáteční datum stabilizační fáze. ',

    'route-title/default': 'Dieta OXY',
    'route-title/day-plan': '',
    'route-title/diet-intro': 'Úvod',
    'route-title/day-plan-with-date': '',
    'route-title/diet-will-be-available-in-future':
        'Dieta bude k dispozici v budoucnu',
    'route-title/current-weight-needed': 'Přidejte aktuální měření',
    'route-title/goal-reached': 'Cíl dosažen',
    'route-title/goal-lost': 'Cíl nedosažen',
    'route-title/make-decision': 'Rozhodněte se',
    'route-title/shopping-list': 'Nákupní seznam',
    'route-title/measurements': 'Měření',
    'route-title/measurements-add': 'Přidejte měření',
    'route-title/measurements-edit': 'Upravit měření',
    'route-title/diet-settings': 'Nastavení diety',
    'route-title/settings': 'Nastavení',
    'route-title/login-by-token': 'Login by Token',
    'route-title/logout': 'Logout',
    'route-title/error-404': 'Chyba 404',
    'route-title/unauthorized-exception': 'Žádný přístup',
    'route-title/content': '',
    'route-title/contact': 'Kontakt',
    'route-title/knowledge-category': 'Znalost',
    'route-title/knowledge': 'Znalost',
    'route-title/knowledge-article': '',
    'route-title/quiz': 'Quiz',
    'route-title/diet-outside-access': 'Žádný přístup',
    'route-title/block-cookies': '',
    'route-title/error-network': 'Došlo k chybě sítě!',
    'route-title/activities': 'Moje aktivity',
    'route-title/activities-index': 'Aktivity',
    'route-title/activities-add': 'Přidat aktivitu',
    'route-title/activities-edit': 'Upravit aktivitu',
    'route-title/promo-code': 'Slevový kupón',
    'route-title/unsubscribe': 'Je nám líto, že odcházíte',
    'route-title/error-application': 'Chyba aplikace',
    'route-title/update': 'Aktualizujte aplikaci',
    'mobile-title/knowledge': 'Znalost',
    'route-title/guides': 'Návody',
    'route-title/psychometric': 'Psychometrický test',

    'alert/periodic-measurements-1':
        'Pravidelná měření tělesné hmotnosti jsou nezbytná ke sledování Vašeho pokroku směrem k cíli.',
    'alert/periodic-measurements-2':
        'Připomínáme Vám, abyste pravidelně doplňovali svou aktuální tělesnou hmotnost. Nejlépe se zvažte dnes!',

    'auth/confirm/header': 'Děkujeme',
    'auth/confirm/content':
        'Váš účet byl aktivován. Můžete začít používat mobilní aplikaci Dieta OXY.',

    'auth/confirm/mobileApps': 'Nemáte mobilní aplikaci? Stáhněte si ji nyní!',

    'auth/login/title': 'Přihlásit se',
    'auth/login/with-google': 'Pokračujte s Google',
    'auth/login/with-facebook': 'Pokračujte s Facebook',
    'auth/login/with-apple': 'Pokračujte s Apple',
    'auth/login/user-exists': 'Účet již existuje. Použijte funkci přihlášení.',
    'auth/login/user-not-exists':
        'Účet neexistuje. Použijte funkci registrace.',
    'auth/login/agreement':
        'Přihlášení znamená přijetí platných podmínek webových stránek. Poslední změny podmínek byly provedeny 20. prosince 2019.',
    'auth/login/back-link-text': 'Zpět k přihlášení',
    'auth/login/menu-link-text': 'Přihlásit se',
    'auth/login/error-message': 'Přihlášení selhalo.',
    'auth/login/social-media-login-header': 'Přihlášení přes sociální sítě',
    'auth/login/social-media-set-up': 'Váš účet je propojen se sociální sítí.',

    'auth/remind-password/title': 'Získat přístup',
    'auth/remind-password/description':
        'Zadejte svůj e-mail a obdržíte zprávu s pokyny ke změně hesla.',
    'auth/remind-password/link-text': 'Nepamatuji si své heslo',
    'auth/remind-password/error-message':
        'Nebyl nalezen žádný uživatel s danou e-mailovou adresou.',
    'auth/remind-password/success-message':
        'Byl zaslán e-mail s pokyny ke změně hesla.',

    'auth/reset-password/title': 'Nastavte nové heslo',
    'auth/reset-password/token-is-not-valid-error-message':
        'Platnost odkazu na nastavení hesla vypršela. Pokud jej stále chcete použít, využijte znovu možnost „Nepamatuji si své heslo“',
    'auth/reset-password/error-message': 'Heslo by mělo mít alespoň 4 znaky.',
    'auth/reset-password/success-message': 'Heslo pro Váš účet bylo nastaveno.',

    'auth/password-is-not-set':
        'Ještě nemáte nastaveno heslo. Kliknutím na tlačítko níže nastavíte heslo.',
    'auth/set-password': 'Nastavit heslo',
    'auth/set-password/title': 'Nastavit heslo',

    email: 'Emailová adresa',
    password: 'Heslo',
    'password-to-login': 'Přihlašovací heslo',
    'new-password': 'Nové heslo',
    name: 'Jméno',

    'form/send': 'Odeslat',
    'validation/required': 'Pole je vyžadováno.',
    'validation/date-format': 'Formát data je nesprávný.',
    'validation/email-format': 'Neplatná e-mailová adresa',

    monday: 'Pondělí',
    tuesday: 'Úterý',
    wednesday: 'Středa',
    thursday: 'Čtvrtek',
    friday: 'Pátek',
    saturday: 'Sobota',
    sunday: 'Neděle',

    'promo-code/page-title': 'Zadejte slevový kupón',
    'promo-code/input-label': 'Slevový kupón',
    'promo-code/missing-value': 'Zadejte slevový kupón',
    'promo-code/invalid-value': 'Neplatný kupón',
    'promo-code/submit': 'Ověřte kupón',
    'promo-code/not-exists': 'Promo kód je neplatný',
    'promo-code/has-been-activated': 'Promo kód byl aktivován',

    'Something went wrong': 'Něco se pokazilo',
    'Incident has been reported and we will work to solve it':
        'Incident byl nahlášen a snažíme se ho vyřešit.',
    'Go back': 'Vrátit se',
    'Try again': 'Zkusit znovu',

    'diet-faq/title': 'Otázky a odpovědi',

    'account/activate/title': 'Aktivace účtu',
    'account/activate/error': 'Aktivace účtu se nezdařila.',

    'coupon/title': 'Aktivace',
    'coupon/continue': 'Pokračujte s účtem:',
    'coupon/register-user/title': 'Vytvořit účet',
    'coupon/access-code': 'Přístupový kód',
    'coupon/login/title': 'Přihlásit se',
    'coupon/activate/title': 'Zadejte přístupový kód',
    'coupon/activated-successfully': 'Kupón byl aktivován',
    'coupon/activate-card/title': 'Aktivujte přístupový kód',
    'coupon/card-instruction': 'Pro použití přístupové karty:',
    'coupon/card-instruction/1': 'vyplňte všechna pole formuláře',
    'coupon/card-instruction/2': 'přepište kód ze své karty',
    'coupon/card-instruction/3':
        'pokud jsou všechna data správná, klikněte na tlačítko.',
    'coupon/send': 'Aktivovat přístup',

    'agreements/terms-and-conditions/text': 'Přijímám ',
    'agreements/terms-and-conditions/text2': 'Podmínky',
    'agreements/terms-and-conditions/text3': ' a ',
    'agreements/terms-and-conditions/text4': 'Zásady ochrany osobních údajů',
    'agreements/terms-and-conditions/text5':
        ' a souhlasím se zpracováním mých osobních údajů.',
    'agreements/processing-personal-data/text':
        'Souhlasím se zpracováním mých osobních údajů',
    'agreements/processing-personal-data/text-more':
        'od DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako správce osobních údajů, za účelem poskytování služeb dostupných na webových stránkách a pro marketingové účely, včetně přijímání obchodních informací zasílaných na e-mailovou adresu od společnosti DietLabs sp. Z o.o. sp. k. svým vlastním jménem nebo jménem svých obchodních partnerů. Máte právo zkontrolovat své údaje a opravit je nebo smazat.',

    'newsletter/unsubscribe/title': 'Je nám líto, že odcházíte',
    'newsletter/unsubscribe/success':
        'E-mailová adresa byla odhlášena z newsletteru.',
    'newsletter/unsubscribe/send': 'Odhlásit',
    'read-more': 'více',

    'cart/title': 'Košík',
    'cart/products': 'Produkty',
    'cart/payment-method': 'Způsob platby',
    'cart/free-delivery-info':
        'Přidejte produkty v celkové hodnotě {{ price }}, pro dopravu zdarma.',
    'cart/delivery/heading': 'Doručovací adresa',
    'cart/delivery/name': 'Jméno a příjmení nebo název společnosti',
    'cart/delivery/street': 'Ulice, číslo domu a bytu',
    'cart/delivery/postal-code': 'Poštovní směrovací číslo',
    'cart/delivery/city': 'Město',
    'cart/delivery/phone': 'Číslo telefonu',
    'cart/delivery/country': 'Země',
    'cart/summary': 'Přehled objednávky',
    'cart/summary/products': 'Produkty celkem',
    'cart/summary/delivery': 'Doručení',
    'cart/summary/total': 'K úhradě',
    'cart/pay': 'Zaplatit',
    'cart/register/login': 'Registrace / Přihlášení',
    'cart/continue-with-email': 'Pokračujte svou e-mailovou adresou',
    'cart/accept-condition/text':
        'Přijímám podmínky a zásady ochrany osobních údajů a souhlasím se zpracováním mých osobních údajů.',
    'cart/accept-condition/text-more':
        'od DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako správce osobních údajů, za účelem poskytování služeb dostupných na webových stránkách a pro marketingové účely, včetně přijímání obchodních informací zasílaných na e-mailovou adresu od společnosti DietLabs sp. Z o.o. sp. k. svým vlastním jménem nebo jménem svých obchodních partnerů. Máte právo zkontrolovat své údaje a opravit je nebo smazat.',
    'cart/accept-condition/validation/required': 'Je vyžadován souhlas',
    'cart/upsell': 'Zákazníci také koupili',
    'cart/upsell/you-save': 'Ušetříte',
    'cart/upsell/add-product': 'Přidat do košíku',
    'cart/upsell/added': 'Přidáno',
    'cart/upsell/about': 'O produktu',
    'cart/next': 'Další',
    'cart/back-to-cart': 'Zpět do košíku',
    'cart/confirm-and-order': 'Potvrzuji a objednávám',
    'cart/change': 'změnit',
    'cart/order-summary': 'Přehled objednávky',
    'cart/order-confirm': 'Potvrzení objednávky',
    'cart/address-of-delivery': 'Doručovací adresa',
    'cart/payment': 'Platba',
    'cart/payment/error-occured':
        'Je nám líto, ale nemohli jsme přijmout Vaši platbu. Zkuste to znovu a dejte nám vědět, pokud problém přetrvává.',
    'cart/payment-cod/error-occured':
        'Je nám líto, ale nemohli jsme dokončit Vaši objednávku. Zkuste to znovu a dejte nám vědět, pokud problém přetrvává. ',
    'cart/order-is-pending': 'Objednávka byla přijata.',
    'cart/add/invalid-product-id': 'Vybraný produkt není dostupný.',
    'cart/login-or': 'nebo',

    'thank-you/title': 'Děkujeme za důvěru! Co bude dál s Vaší objednávkou?',
    'thank-you/how-to-activate-the-service': 'Aktivace služby',
    'thank-you/step1':
        'Na Váš e-mail jsme odeslali zprávu se souhrnem a potvrzením objednávky.',
    'thank-you/step2':
        'Ihned potom jsme začali připravovat Vaši objednávku k provedení. Jakmile bude zásilka připravena k odeslání, upozorníme Vás e-mailem!',
    'thank-you/step3':
        'Chcete sledovat cestu své zásilky? Když je zásilka odeslána, obdržíte od nás zprávu se sledovacím kódem a podrobnými pokyny, jak sledovat objednávku. Průměrná čekací doba na zásilku je pouze 2 pracovní dny!',
    'thank-you/activation-code-and-instruction':
        'V záslice najdete aktivační kód a informace o jeho použití.',
    'thank-you/flyer-header': 'Dieta OXY - přístupový kód',
    'thank-you/flyer-content1':
        'Cesta ke štíhlé postavě může být současně jednoduchá, rychlá, chutná a levná. Už brzy se o tom přesvědčíte!',
    'thank-you/flyer-content2':
        'S naším jídelníčkem jíte pouze to, na co máte chuť a s radostí sledujete, jak hubnete přebytečné kilogramy.',
    'thank-you/flyer-content3': 'Tým dietologů OXY',
    'thank-you/flyer-footer1': 'Přejděte na stránku ',
    'thank-you/flyer-footer2': ', zadejte níže uvedený kód a aktivujte službu.',
    'thank-you/go-to-diet-plan': 'Přejděte na dietní plán',
    'thank-you/go-to-diet-plan/content1': 'Váš dietní plán je již připraven.',
    'thank-you/go-to-diet-plan/content2':
        'Jezte to, co chcete, a užívejte si štíhlou postavu!',
    'thank-you/start-configuring-your-diet': 'Začněte s nastavením Vaší diety',
    'thank-you/start-configuring-your-diet/content':
        'Brzy budete moci začít s dietou! Než k tomu dojde, potřebujeme od Vás několik dalších podrobností.',
    'thank-you/start-configuring-your-diet/button': 'Nastavit dietu',
    'thank-you/warning':
        'POZOR! Chcete-li plně využít funkce webových stránek, aktivujte svůj účet. Poslali jsme Vám aktivační odkaz na následující adresu: {{email}}. Pokud jste e-mail [neobdržela| neobdržel], zkontrolujte prosím složku se spamem.',
    'loading/message': 'Načítání…',
    'et-al/abbrev': 'atd',
    'et-al/full': 'a tak dále',

    'payment/pending/title':
        '[Objednala jste|Objednal jste] balíček: {{ products }}',
    'payment/pending/description':
        'Přístup k Vašim službám získáte, jakmile obdržíme Vaši platbu. Čekací doba závisí na Vámi zvolené platební metodě.',
    'payment/pending/make-payment-description':
        'Pokud jste [neprovedla|neprovedl] ještě platbu, můžete tak učinit nyní.',
    'payment/pending/make-payment': 'Zaplatit nyní',

    'message/showing-cached-data': 'Data nemusí být aktuální.',
    'message/showing-cached-data/network-error':
        'Stažení dat se nezdařilo - zobrazené údaje mohou být neaktuální.',
    'message/showing-cached-data/network-error-offline':
        'Žádné připojení k internetu - zobrazené údaje mohou být neaktuální.',

    'units/mililiter/full': 'mililitr(ů)',
    'units/mililiter/full_0': 'mililitr',
    'units/mililiter/full_1': 'mililitry',
    'units/mililiter/full_2': 'mililitrů',
    'units/mililiter/full_3': 'mililitru',
    'units/mililiter/abbr': 'ml',

    'units/ounce/full': 'uncí',
    'units/ounce/full_0': 'unce',
    'units/ounce/full_1': 'unce',
    'units/ounce/full_2': 'uncí',
    'units/ounce/full_3': 'uncí',
    'units/ounce/abbr': 'oz',

    'route-title/hydration-day': 'Vaše hydratace',
    'menu/hydration': 'Hydratace',
    'menu/your-hydration': 'Vaše hydratace',
    'hydration/add-custom': 'Přidejte vlastní dávku vody',
    'hydration/goal-label': 'Denní cíl:',
    'hydration/goal-changer-label': 'Váš denní cíl',
    'hydration/goal-reached-label': 'BRAVO!',
    'hydration/goal-reached':
        'Dosáhl/a jste svého denního cíle hydratace. Pamatujte, že dobrá hydratace přispívá ke správnému fungování organismu.',
    'hydration/goal-left/past/pre-value': 'Zbývá Vám vypít ještě',
    'hydration/goal-left/past/post-value': 'vody',
    'hydration/goal-left/present/pre-value':
        'Dnes byste [měla|měl] vypít ještě',
    'hydration/goal-left/present/post-value': 'vody',
    'hydration/goal-left/future/pre-value':
        '{{date}} [měla byste|měl byste] vypít',
    'hydration/goal-left/future/post-value': 'vody',
    'hydration/progress/label': 'vypito',
    'hydration/remove-last-portion': 'Odstraňte poslední dávku vody',
    'route-title/hydration-add': 'Přidejte dávku vody',
    'hydration/add-portion': 'Přidejte dávku',
    'hydration/add/heading': 'Kolik vody jste [vypila|vypil]?',
    'hydration/add/submit': 'Přidat',
    'hydration/add/cancel': 'Zrušit',
    'hydration/add/custom': 'vlastní',
    'hydration/full-glass': 'Plná sklenice',
    'hydration/full-bottle': 'Plná láhev',
    'hydration/add-global-action': 'Přidejte vodu, kterou jste dnes vypil/a',
    'route-title/hydration-history': 'Historie hydratace',
    'menu/hydration-history': 'Historie',
    'hydration/go-to-history': 'Historie',
    'hydration/back-to-hydration': 'Zpět',
    'hydration/recommended-value': '{{value}} (doporučeno)',
    'hydration/glass-value': '{{value}} (sklenice)',
    'hydration/glass': 'sklenice',
    'hydration/half-bottle-value': '{{value}} (půl láhve)',
    'hydration/bottle-value': '{{value}} (láhev)',
    'hydration/bottle': 'láhev',
    'hydration/goal-header/reached': 'Bravo! Denní cíl byl dosažen.',
    'hydration/goal-reached1':
        'Malými kroky k cíli - a dnes se Vám podařilo dosáhnout toho, který se týká pití vody.',
    'hydration/goal-reached2':
        'Je to nesmírně důležité pro správné fungování organismu!',
    'default-value': 'Výchozí hodnota',
    'individual-goal': 'Individuální cíl',

    'route-title/interventions': 'Motivace',
    'interventions/title': 'Motivace',
    'interventions/prev': 'Předchozí',
    'interventions/next': 'Další',
    'interventions/back-to-intro': 'Zpět na úvod',
    'interventions/go-to-motivation': 'Přejít na motivaci',
    'interventions/back-to-motivation': 'Zpět na seznam',
    'intervention-type/quote': 'Nechte se inspirovat',
    'intervention-type/question': 'Otázka od trenéra',
    'intervention-type/behavioral': 'Váš úkol pro dnešek',
    'intervention-type/psychological': 'Poznejte sám/sama sebe',

    'motivation-test/question': 'Otázka',
    'motivation-test/agree': 'NAPROSTO SOUHLASÍM',
    'motivation-test/disagree': 'VŮBEC NESOUHLASÍM',
    'motivation-test/next': 'Další',
    'motivation-test/prev': 'Předchozí',
    'motivation-test/send': 'Odeslat',

    'product/you-save': 'Ušetříte',
    'product/buy-now': 'Kupte nyní',

    'guide/title': 'DIETNÍ PRŮVODCE OXY',
    'guide/intro':
        'Speciálně pro Vás jsem připravila průvodce, kteří perfektně doplňují Dietu OXY. Zde najdete další dietní recepty, masáže, cvičení, recepty na domácí kosmetiku, motivaci, inspiraci a mnoho dalšího.',
    'guide/featured/title': 'Svačiny OXY na cestách',
    'guide/featured/description':
        ' Nadešel čas výletů, prázdnin a volných dnů. Než si užijete zasloužený odpočinek, čeká Vás ještě cesta do cíle, která je často dlouhá a únavná. Což neznamená, že jste odsouzeni k tomu, abyste se zachraňovali nezdravým a vysokokalorickým občerstvením z čerpací stanice! S naším speciálním průvodcem zjistíte (po kolikáté už to je?), že zdravé stravování je možné za všech, i zdánlivě nepříznivých podmínek. Šťastnou dovolenou!',
    'guide/download': 'Stáhnout průvodce',

    'psychometric/email-address':
        'Zadejte svou e-mailovou adresu a zjistěte výsledky testu',
    'psychometric/show-test-results': 'Zjistěte výsledek testu',
    'psychometric/bubble': 'Jaký typ osobnosti jste?',
    'psychometric/email-title': 'Jste připraven/a zobrazit výsledky TESTU?',
    'psychometric/did-you-knpw':
        'Podle našich analýz až 93% výsledků plně odráží osobnost. K poznání výsledku testu Vám zbývá už jen jeden krok.',
    'psychometric/why': 'Proč je test osobnosti tak důležitý? Je to snadné',
    'psychometric/why/answer1':
        'Dostanete dietu přizpůsobenou Vašemu charakteru',
    'psychometric/why/answer2': 'Dosáhnete svého cíle',
    'psychometric/why/answer3': 'Jídelníček bude vyhovovat Vašim chutím',
    'psychometric/guide/title':
        'Spolu s výsledky testu obdržíte průvodce\n "Chuť hubnutí" - ZDARMA',
    'psychometric/show-result': 'Zobrazit výsledek',
    'psychometric/how-your-diet-should-look-like':
        'Jak by měla vypadat Vaše dieta?',

    'psychometric/result/who-you-are': '[Jaká|Jaký] jsi?',
    PERSONALITY_1_IMG: 'Máte rád/a osvědčená klasická jídla',
    PERSONALITY_1_PROMO_SECTION:
        'Vaše dieta by se měla skládat z chutných a klasických pokrmů, jejichž příprava nebude časově náročná! Můžeme ji pro Vás sestavit!',
    PERSONALITY_1_PROMO_TITLE:
        'Už víme, že jste [individualistka|individualista] a nemáte rád/a změny!',
    PERSONALITY_1_SECTION:
        'Jste jedním z lidí citlivých na stres. Na vzniklé problémy obvykle reagujete velmi emocionálně. Snažíte se proto konfliktům vyhýbat nebo je odvracet. Jste typ samotáře. Místo fantastických večírků s mnoha lidmi si vyberete společnost svých blízkých nebo partnera. Cítíte se bezpečně ve známém prostředí a dáváte přednost věcem, které jsou vyzkoušené a ověřené. Ke všem změnám a inovacím přistupujete s odstupem.',
    PERSONALITY_1_SECTION_2:
        'Díky své povaze se cítíte k novým věcem neochotně a někdy se jich dokonce bojíte. Je pro Vás obtížné vyvinout úsilí potřebné k provedení změn. Nejčastěji si vybíráte známá jídla, ne taková, jejichž chuť Vás může příliš překvapit. Nejlepší dietou pro Vás bude ta, která nebude vyžadovat velké odhodlání z Vaší strany připravovat pokrmy a potřebné produkty si koupíte ve svých oblíbených obchodech.',
    PERSONALITY_2_IMG: 'Máte rád/a osvědčená jednoduchá jídla',
    PERSONALITY_2_PROMO_SECTION:
        'Vaše dieta by měla skládat z jídel, která Vám chutnají a budou vyhovovat Vaší chuti a vkusu. Váš jídelníček musí být promyšlený do všech detailů. Můžeme ho pro Vás sestavit!',
    PERSONALITY_2_PROMO_TITLE:
        'Víme, že jste [perfekcionistka|perfekcionista] a nemáte rád/a změny!',
    PERSONALITY_2_SECTION:
        'Jste osoba citlivá na stres a kritiku, a proto prožíváte jakékoli konfliktní situace velmi osobně. Často se vyhýbáte situacím, které mohou způsobit nepříjemné reakce lidí kolem Vás. Zároveň jste typem [individualistky|individualisty]. [Sam|Sám] si vybíráte svou cestu! Pokud chcete dosáhnout cíle, vytrvale o něj usilujete a jste ochotna/ý k sebezapření a obětování. Jste spolehlivá/ý a pečlivý/á. [Jaká|Jaký] jste v kontaktu s jinými lidmi? Pokud chcete s někým trávit čas, obvykle jsou to Vaši nejbližší nebo [partner|partnerka].',
    PERSONALITY_2_SECTION_2:
        'Ke změnám a inovacím ve Vašem životě a okolí přistupujete s velkým odstupem. Pokud už musíte něco změnit, volíte spolehlivá a osvědčená řešení. To je také případ jídla. V kuchyni jste [věrná|věrný] svým zvykům a chutím. Vybíráte si osvědčená a známá jídla, i když vyžadují dlouhou dobu vaření. Ideální dieta je pro Vás tedy ta, která bude chutnat a bude mít podobné přísady jako jsou Vaše chutě.',
    PERSONALITY_3_IMG: 'Máte rádi originální chutě z celého světa',
    PERSONALITY_3_PROMO_SECTION:
        'Vaše ideální dieta bude ta, která bude bohatá na nové chutě a neobvyklá jídla, ze kterých budete jednoduše nadšeni! Máme ji pro Vás sestavit?',
    PERSONALITY_3_PROMO_TITLE:
        'Víme, že jste [dravá|dravý] a [společenská|společenský]!',
    PERSONALITY_3_SECTION:
        'Jste impulzivní a spontánní člověk. Stává se, že se v konfliktních situacích necháte unést. Zároveň se cítíte dobře ve společnosti ostatních. Nemáte problém s navázáním nových kontaktů a přátel. Rád/a zkoušíte nové věci a jste ochotni zapojit se do aktivit navržených ostatními. Jste kreativní člověk a máte velmi širokou škálu zájmů. Existuje také druhá strana mince: Vaše povaha vám nedovolí držet se jednoho rozhodnutí dostatečně dlouho. Rád/a zkoušíte nové věci, ale rychle se od nich odradíte a chytnete se dalších.',
    PERSONALITY_3_SECTION_2:
        'Máte rád/a nové kombinace a experimenty v kuchyni. Díky tomu Vám bude chutnat dieta obsahující originální chutě z celého světa. Nejste však typ, který by strávil spoustu času přípravou jídla. Nejvhodnějším řešením pro Vás proto bude zajímavá a originální dieta, která by se měla skládat z rychle připravených jídel.',
    PERSONALITY_4_IMG: 'Máte rád/a originální a zajímavé recepty',
    PERSONALITY_4_PROMO_SECTION:
        'Dieta, která se k Vám dokonale hodí, bude plná originálních a zajímavých receptů a zcela nových chutí! Měli bychom ji pro Vás sestavit?',
    PERSONALITY_4_PROMO_TITLE:
        'Víme,že jste [společenská|společenský] a [důsledná|důsledný]!',
    PERSONALITY_4_SECTION:
        'Jste velmi emotivní člověk. Pokud se nacházíte v konfliktní situaci, je pro Vás těžké udržet klidný přístup s odstupem. Slova kritiky a nesouhlasu berete velmi osobně. Jste však společensky přátelská/ý - těžko si dokážete představit, že strávíte čas sama/sám. Vaše výhoda je mimo jiné to, že nemáte problémy se ocitnout v cizím prostředí. Toužíte zkoušet nové věci a řešení. Se vší svou spontánností jste důslední a když Vám na něčem záleží, uděláte téměř cokoli, abyste toho dosáhl/a!',
    PERSONALITY_4_SECTION_2:
        'Máte rád/a kulinářské experimenty a jste [otevřena|otevřený] novým chuťovým zážitkům. Nejlepším řešením pro Vás bude sestavení originálních a zajímavých receptů, které Vás nenechají se nudit a upadnout do rutiny. Kreativní a tvůrčí pokrmy jsou to, co máte na jídle nejraději. Další informace a zajímavosti o výživě týkající se výživy, obohatí Vaše znalosti a probudí ještě větší motivaci pracovat.',
    PERSONALITY_5_IMG: 'Máte rád/a osvědčená jednoduchá jídla',
    PERSONALITY_5_PROMO_SECTION:
        'Dieta vytvořená speciálně pro Vás musí být nezajímavá a musí se skládat z klasických jídel, která Vám chutnají! Můžeme ji pro Vás sestavit!',
    PERSONALITY_5_PROMO_TITLE:
        'Víme, že jste [vyrovnaná|vyrovnaný] a nemáte rád/a změny!',
    PERSONALITY_5_SECTION:
        'Jste stabilní a vyrovnaný člověk. Nemáte velké problémy se stresem. Pokud se ve Vašem životě vyskytují problémy, zaměřujete se na jejich řešení. Snažíte se nevytvářet konflikty, často za cenu vlastního pohodlí. Přílišná ochota potěšit všechny kolem Vás, může způsobit to, že budete zanedbávat své vlastní potřeby. Ve společenských kontaktech projevujete velkou rezervu a distanc. Pokud někomu říkáte přítel, musí to být opravdu blízký člověk. Změny nejsou ve Vaší povaze, přijímáte je neochotně. Přistupujete k novým a neznámým věcem s velkou rezervou. Zdráháte se také přijmout nové výzvy.',
    PERSONALITY_5_SECTION_2:
        'Nejčastěji si vybíráte jídla, která jsou Vám známá, a ne ta, jejichž původ a chuť Vás mohou překvapit. Nejlepší dietou pro Vás bude ta, která nebude vyžadovat velké odhodlání z Vaší strany při přípravě pokrmů a produkty potřebné k jejich přípravě si budete moci koupit ve svých oblíbených obchodech.',
    PERSONALITY_6_IMG: 'Máte rád/a osvědčená jednoduchá jídla',
    PERSONALITY_6_PROMO_SECTION:
        'Vaše dieta musí odpovídat Vašim zvykům a Vašim oblíbeným chutím. Proto by se měla skládat z pokrmů, které zohledňují Vaše zvyky a denní rytmus Vašeho dne! Můžeme ji pro Vás sestavit!',
    PERSONALITY_6_PROMO_TITLE:
        'Víme,že jste [vyrovnaná|vyrovnaný] a nemáte rád/a změny!',
    PERSONALITY_6_SECTION:
        'Jste jedním z lidí, kteří nemají problémy se stresem. Umíte řešit konflikty efektivně. Jste schopna/ý se bez problému vypořádat s problémem a v případě potřeby učinit kompromis. Tento přístup bohužel znamená, že pokud chcete udělat dobrý dojem na ostatní, můžete odvrátit pozornost od svých problémů týkajících se osobní sféry nebo zdraví. Ve společenských kontaktech jste spíše [opatrná|opatrný]. Aby se k Vám někdo přiblížil, musí nejprve získat Vaši důvěru. Kromě toho nemáte důvěru v nová řešení a neznámé věci. Cítíte se dobře ve známých situacích, kdy rozdáváte karty. Máte jasně definované, určené cíle, které pečlivě a důsledně plníte.',
    PERSONALITY_6_SECTION_2:
        'V kuchyni jste [věrná|věrný] svým zvykům a chutím. Připravujete osvědčená a známá jídla, i když vyžadují delší dobu přípravy. Ideální dieta je pro Vás tedy ta, která bude chutnat a bude mít podobné přísady jako Vaše chutě.',
    PERSONALITY_7_IMG: 'Máte rád/a originální a jednoduchá jídla',
    PERSONALITY_7_PROMO_SECTION:
        'Dieta pro Vás by měla být originální a obsahovat chutě z celého světa! Příprava jídel však nemůže být časově náročná. Můžeme ji pro Vás sestavit!',
    PERSONALITY_7_PROMO_TITLE:
        'Víme, že jste [společenská|společenský] a máte rád/a změny!',
    PERSONALITY_7_SECTION:
        'Dokážete dobře zvládat napětí a stres. V konfliktních situacích se dokážete přizpůsobit okolí, když je pro Vás však situace velmi napjatá a obtížná, pod maskou sebeovládání se skrývají extrémně silné emoce. Zároveň jste veselým a pohodovým člověkem. Máte rádi společnost jiných lidí. Máte spoustu energie, jste ochotni věnovat se mnoha novým činnostem. Cítíte se obzvláště pohodlně v úkolech, které vyžadují nekonvenční a kreativní myšlení. Má to také svoji „nevýhodu“ - je pro Vás těžké vytrvat ve svých rozhodnutích, po nějaké době se pro Vás opakované činnosti stanou nudnými a neatraktivními.',
    PERSONALITY_7_SECTION_2:
        'Máte rád/a nové kombinace a experimenty v kuchyni. Výsledkem je, že se Vám bude líbit jakákoli dieta s originálními chutěmi z celého světa. </p><p> Nejste však ten typ, který by strávil spoustu času přípravou jídla. Proto pro Vás bude nejlepším řešením zajímavá a originální a zároveň rychlá a snadná dieta.',
    PERSONALITY_8_IMG: 'Máte rád/a originální, zajímavé recepty',
    PERSONALITY_8_PROMO_SECTION:
        'Vaše dieta by měla být originální, nesmírně zajímavá a skládat se z mimořádných jídel. Rád/a používáte efektivní řešení - a Vaše dieta také taková bude! Můžeme ji pro Vás sestavit!',
    PERSONALITY_8_PROMO_TITLE:
        'Víme, že jste [klidná|klidný] a máte rád/a nekonvenční řešení!',
    PERSONALITY_8_SECTION:
        'Jste vyrovnaná osoba, která funguje velmi dobře v situacích i silného stresu. Umíte vyřešit konflikty a problémy, i když se ukazují jako velmi komplikované. Vaše vyrovnanost a zdravý rozum umožňují zvládnout i velké množství lidí. Jste skvělým vedoucím týmu nebo manažerem, koneckonců můžete důsledně uskutečňovat své cíle. Ve skupině se cítíte dobře, máte rádi společnost jiných lidí. Se získáváním nových přátel a kontaktů nemáte sebemenší problém, a to nejen pro osobní, ale i profesionální účely. Kromě toho máte rádi inovativní a kreativní řešení a nebojíte se výzev!',
    PERSONALITY_8_SECTION_2:
        'Máte rádi kulinářské experimenty a jste [otevřená|otevřený] novým chuťovým vjemům. Nejlepším řešením pro Vás bude sestavení originálních a zajímavých receptů, které Vás nenechají se nudit a upadnout do rutiny. Kreativní a tvůrčí pokrmy jsou to, co na jídle máte nejraději!',

    'terms-of-service/title': 'OBCHODNÍ PODMÍNKY',
    'terms-of-service/android': 'Nákup v Google Play',
    'terms-of-service/ios': 'Nákup v App Store',
    'terms-of-service/web': 'Nákup přes web',
};
