import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShoppingCartConfirmPaymentOnlinePlaceholder from 'components/ShoppingCart/Confirm/Payment/ShoppingCartConfirmPaymentOnlinePlaceholder';
import ShoppingCartConfirmPaymentOnlineComponent from 'components/ShoppingCart/Confirm/Payment/ShoppingCartConfirmPaymentOnlineComponent';
import { shoppingCartPaymentMethodsLoadAction } from 'view/ShoppingCart/Payment/Method/Load/ShoppingCartPaymentLoadAction';
import { shoppingCartPaymentMakeAction } from 'view/ShoppingCart/Confirm/Payment/Make/ShoppingCartPaymentMakeAction';

export class ShoppingCartConfirmPaymentOnlineContainer extends Component {
    static propTypes = {
        load: PropTypes.func.isRequired,
        make: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
    };

    state = {
        paymentMethods: null,
    };

    async componentDidMount() {
        await this.load();
    }

    load = async () => {
        const paymentMethods = await this.props.load();
        this.setState({ paymentMethods });
    };

    render() {
        return this.state.paymentMethods === null ? (
            <ShoppingCartConfirmPaymentOnlinePlaceholder />
        ) : (
            <ShoppingCartConfirmPaymentOnlineComponent
                make={this.props.make}
                token={this.props.token}
                paymentMethods={this.state.paymentMethods}
            />
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    load: () => shoppingCartPaymentMethodsLoadAction(),
    make: (token, method, channel, successUrl, cancelUrl) =>
        dispatch(
            shoppingCartPaymentMakeAction(
                token,
                method,
                channel,
                successUrl,
                cancelUrl
            )
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartConfirmPaymentOnlineContainer);
