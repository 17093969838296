import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    Collapse,
    Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import android from 'assets/icons/android.svg';
import apple from 'assets/icons/apple.svg';
import instagram from 'assets/icons/instagram.svg';
import facebook from 'assets/icons/facebook.svg';
import activerse from 'assets/icons/activerse_logo_horizontal.svg';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import { GOOGLE_PLAY_URL, APP_STORE_URL, INSTAGRAM, FACEBOOK } from 'consts';
import logo from 'assets/icons/logo.svg';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';
import { PATHS } from '../config/paths';

class Footer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        forceReloadLinks: PropTypes.bool,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    state = {
        isOpen: false,
    };

    renderNavLink(href, text) {
        if (this.props.forceReloadLinks) {
            return (
                <a className="nav-link" href={href}>
                    {text}
                </a>
            );
        }

        return (
            <Link className="nav-link" to={href}>
                {text}
            </Link>
        );
    }

    handleClick = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };

    render() {
        return (
            <footer className="d-print-none">
                <Container>
                    <img src={logo} className="logo" alt="Dieta OXY" />

                    <Button
                        onClick={() => this.handleClick()}
                        className="d-md-none"
                        color="link"
                    >
                        <span
                            className={
                                this.state.isOpen
                                    ? 'dropdown-arrow open'
                                    : 'dropdown-arrow'
                            }
                        >
                            <span className="SVGInline">
                                <DownArrow />
                            </span>
                        </span>
                    </Button>
                    <Row className="mt-4">
                        <Col xs="12" md="3">
                            <Collapse isOpen={this.state.isOpen}>
                                <p className="d-md-none">
                                    DietLabs sp. z o.o. sp. k.
                                    <br />
                                    ul. Głogowska 31/33
                                    <br />
                                    60-702 Poznań
                                    <br />
                                    <br />
                                    KRS: 0000679107
                                    <br />
                                    NIP: 7792414027
                                    <br />
                                    REGON: 302447233
                                </p>
                            </Collapse>

                            <p className="d-none d-md-block">
                                DietLabs sp. z o.o. sp. k.
                                <br />
                                ul. Głogowska 31/33
                                <br />
                                60-702 Poznań
                                <br />
                                <br />
                                KRS: 0000679107
                                <br />
                                NIP: 7792414027
                                <br />
                                REGON: 302447233
                            </p>
                        </Col>
                        <Col xs="12" md="3">
                            <Nav vertical>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/oxy365',
                                        this.props.t('footer/oxy365')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/how-to',
                                        this.props.t('footer/service-access')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/terms-of-service',
                                        this.props.t('footer/tos')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/privacy-policy',
                                        this.props.t('footer/privacy-policy')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        PATHS.CONTACT,
                                        this.props.t('footer/contact')
                                    )}
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xs="12" md="3">
                            <h2 className="mt-4 mt-md-0">
                                {this.props.t('footer/follow-us')}
                            </h2>
                            <a
                                href={FACEBOOK}
                                className="social-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={facebook} alt="" />
                            </a>
                            <a
                                href={INSTAGRAM}
                                className="social-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={instagram} alt="" />
                            </a>
                        </Col>
                        <Col xs="12" md="3">
                            <h2 className="mt-4 mt-md-0">
                                Stáhněte si aplikaci
                            </h2>
                            <a
                                href={GOOGLE_PLAY_URL}
                                className="social-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={android} alt="Google Play" />
                            </a>
                            <a
                                href={APP_STORE_URL}
                                className="social-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={apple} alt="App Store" />
                            </a>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md="6">
                            <p className="small">
                                proudly created by{' '}
                                <a href="https://activerse.app/">
                                    <img
                                        src={activerse}
                                        className="ml-2"
                                        alt="activerse.app"
                                    />
                                </a>
                            </p>
                        </Col>
                        <Col md="6">
                            <p className="small text-md-right">
                                Copyright ©2019-
                                {new Date().getFullYear()} Dieta OXY
                            </p>
                        </Col>
                    </Row>

                    <div className="oxy-color-separator mt-2" />
                </Container>
            </footer>
        );
    }
}

export default withLocale(Footer);
