import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
// import { dataIdFromObject } from '@dietlabs/components/src/Apollo/Apollo';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import DayPlanReplaceProductMutation from './DayPlanReplaceProductMutation';
import DayPlanQuery from '../Fetch/DayPlanQuery';
// import optimisticResponse from './ReplaceProductOptimisticResponse';
// import IngredientFragment from './IngredientFragment';

class DayPlanReplaceProductConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
    };

    createReplaceProduct = mutate => async ({
        productAmountId,
        replacementId,
        // key,
        // name,
        // measurements,
    }) => {
        const variables = {
            request: {
                date: formatIsoDate(this.props.date),
                productAmountId,
                replacementId,
            },
        };

        const response = await mutate({
            variables,
            // optimisticResponse,

            // update: cache => {
            //     const newIngredientData = {
            //         __typename: 'UserDayPlanEventMealDishIngredient',
            //         key,
            //         productId: replacementId,
            //         name,
            //         measurements,
            //     };

            //     cache.writeFragment({
            //         fragment: IngredientFragment,
            //         id: dataIdFromObject(newIngredientData),
            //         data: newIngredientData,
            //     });
            // },
        });

        return response;
    };

    render() {
        const { children, date } = this.props;

        return (
            <Mutation
                mutation={DayPlanReplaceProductMutation}
                refetchQueries={() => [
                    {
                        query: DayPlanQuery,
                        variables: {
                            date: formatIsoDate(date),
                            canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                        },
                    },
                ]}
            >
                {mutate =>
                    children({
                        replaceProduct: this.createReplaceProduct(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default DayPlanReplaceProductConnectorApollo;
