import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from 'reactstrap';
import { PATHS } from 'config/paths';
import logo from 'assets/icons/logo.svg';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';

import mainNavigationLogin from 'config/navigation/mainNavigationLoginUser';
import mainNavigationLogout from 'config/navigation/mainNavigationLogoutUser';
import userNavigation from 'config/navigation/userNavigation';

import routes from 'config/routes';
import scrollToElement from 'components/Helpers/scrollToElement';

import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withLocale } from '@dietlabs/components';

import UserDataQuery from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataQuery';
import { withApollo } from 'react-apollo';

import SimpleMenu from './SimpleMenu';
import { MENU } from '../consts';

class MenuLayout extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        match: PropTypes.shape({
            path: PropTypes.string.isRequired,
        }).isRequired,
        forceReloadLinks: PropTypes.bool,
        client: PropTypes.shape({
            readQuery: PropTypes.func.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    state = {
        isOpenMainMenu: false,
        isOpenUserMenu: false,
    };

    renderLogo = () => (
        <React.Fragment>
            <img src={logo} className="img-fluid d-md-block" alt="Dieta OXY" />
        </React.Fragment>
    );

    toggleMainMenu = () => {
        if (this.state.isOpenUserMenu) {
            this.setState(prevState => ({
                isOpenMainMenu: !prevState.isOpenMainMenu,
                isOpenUserMenu: false,
            }));
        } else {
            this.setState(prevState => ({
                isOpenMainMenu: !prevState.isOpenMainMenu,
            }));
        }
    };

    toggleUserMenu = () => {
        if (this.state.isOpenMainMenu) {
            this.setState(prevState => ({
                isOpenMainMenu: false,
                isOpenUserMenu: !prevState.isOpenUserMenu,
            }));
        } else {
            this.setState(prevState => ({
                isOpenUserMenu: !prevState.isOpenUserMenu,
            }));
        }
    };

    fakeToggle = () => {}; //eslint-disable-line

    renderNavLink = ({ externalUrl, path, translationKey, exact = false }) => {
        if (externalUrl) {
            return (
                <a className="nav-link" href={externalUrl}>
                    {this.props.t(translationKey)}
                </a>
            );
        }

        if (this.props.forceReloadLinks) {
            return (
                <a className="nav-link" href={path}>
                    {this.props.t(translationKey)}
                </a>
            );
        }

        return (
            <NavLink to={path} className="nav-link" exact={exact}>
                {this.props.t(translationKey)}
            </NavLink>
        );
    };

    renderNavItem = ({ path, ...node }) => (
        <NavItem key={path}>{this.renderNavLink({ path, ...node })}</NavItem>
    );

    renderMenuLogin = () => {
        let name;

        if (this.props.client && this.props.client.readQuery) {
            try {
                const userData = this.props.client.readQuery({
                    query: UserDataQuery,
                });

                name = userData.me.name;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }

        return (
            <section id="main-menu" className="d-print-none">
                <Navbar
                    expand="lg"
                    className={this.state.isOpenMainMenu ? 'navbar-open' : ''}
                >
                    <Container>
                        <NavbarToggler onClick={this.toggleMainMenu}>
                            {this.props.t('menu')}
                            <span className="dropdown-arrow ml-2">
                                <span className="SVGInline">
                                    <DownArrow />
                                </span>
                            </span>
                        </NavbarToggler>

                        <a
                            className="navbar-brand d-lg-inline-block"
                            href={PATHS.EXTERNAL.HOME}
                        >
                            {this.renderLogo()}
                        </a>

                        <Button
                            className="dropdown-toggler d-lg-none"
                            onClick={this.toggleUserMenu}
                        >
                            {this.props.t('menu/account')}

                            <span
                                className={
                                    this.state.isOpenUserMenu
                                        ? 'dropdown-arrow ml-2 open'
                                        : 'dropdown-arrow ml-2'
                                }
                            >
                                <span className="SVGInline">
                                    <DownArrow />
                                </span>
                            </span>
                        </Button>

                        <Collapse
                            isOpen={this.state.isOpenUserMenu}
                            className="user-menu-collapse d-lg-none"
                        >
                            <Nav navbar>
                                {userNavigation.map(this.renderNavItem)}
                            </Nav>
                        </Collapse>

                        <Collapse isOpen={this.state.isOpenMainMenu} navbar>
                            <Nav navbar>
                                {mainNavigationLogin.map(node => (
                                    <NavItem
                                        key={node.path}
                                        className={
                                            node.path === PATHS.EXTERNAL.HOME
                                                ? 'd-block d-lg-none'
                                                : ''
                                        }
                                    >
                                        {this.renderNavLink(node)}

                                        {/* render subnav */}
                                        {node.children ? (
                                            <section className="subnav">
                                                <Container>
                                                    <Nav navbar>
                                                        {node.children.map(
                                                            this.renderNavItem
                                                        )}
                                                    </Nav>
                                                </Container>
                                            </section>
                                        ) : (
                                            ''
                                        )}
                                    </NavItem>
                                ))}
                            </Nav>
                        </Collapse>

                        <div className="d-none d-lg-block">
                            <Dropdown
                                isOpen={this.state.isOpenUserMenu}
                                toggle={this.fakeToggle}
                            >
                                <DropdownToggle
                                    tag="span"
                                    className="dropdown-toggler"
                                    onClick={this.toggleUserMenu}
                                >
                                    {this.props.t('menu/account')}
                                    <span className="dropdown-arrow ml-2">
                                        <span className="SVGInline">
                                            <DownArrow />
                                        </span>
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {name ? (
                                        <React.Fragment>
                                            <DropdownItem header>
                                                <span className="blue mr-2">
                                                    <i className="fas fa-user-circle blue" />
                                                </span>
                                                {name ? name.split(' ')[0] : ''}
                                            </DropdownItem>
                                            <DropdownItem divider />
                                        </React.Fragment>
                                    ) : (
                                        ''
                                    )}
                                    {userNavigation.map(node => (
                                        <DropdownItem key={node.path}>
                                            {this.renderNavLink(node)}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Container>
                </Navbar>
            </section>
        );
    };

    renderMenuLogout = () => {
        const theRoute = routes.find(
            route => route.path === this.props.match.path
        );

        let menuButton;
        let menuText;

        if (theRoute.menu) {
            menuButton = theRoute.menu.button;
            menuText = theRoute.menu.text;
        }

        return (
            <section id="main-menu">
                <Navbar
                    expand="lg"
                    className={
                        this.state.isOpenMainMenu
                            ? 'navbar-open logout'
                            : 'logout'
                    }
                >
                    <Container>
                        <a className="navbar-brand" href={PATHS.EXTERNAL.HOME}>
                            {this.renderLogo()}
                        </a>

                        {menuButton ? (
                            <Button
                                color="blue"
                                className="auto-width"
                                onClick={() =>
                                    scrollToElement(
                                        document.getElementById('buy')
                                    )
                                }
                            >
                                {menuButton}
                            </Button>
                        ) : (
                            ''
                        )}
                        {menuText || ''}

                        <NavbarToggler
                            onClick={this.toggleMainMenu}
                            className="d-lg-none"
                        >
                            {this.props.t('menu')}

                            <span className="dropdown-arrow ml-2">
                                <span className="SVGInline">
                                    <DownArrow />
                                </span>
                            </span>
                        </NavbarToggler>

                        <Collapse isOpen={this.state.isOpenMainMenu} navbar>
                            <Nav navbar className="d-lg-none">
                                {mainNavigationLogout.map(node => (
                                    <NavItem
                                        key={node.path}
                                        className={
                                            node.path === PATHS.EXTERNAL.HOME
                                                ? 'd-block d-lg-none'
                                                : ''
                                        }
                                    >
                                        {this.renderNavLink(node)}

                                        {/* render subnav */}
                                        {node.children ? (
                                            <section className="subnav">
                                                <Container>
                                                    <Nav navbar>
                                                        {node.children.map(
                                                            this.renderNavItem
                                                        )}
                                                    </Nav>
                                                </Container>
                                            </section>
                                        ) : (
                                            ''
                                        )}
                                    </NavItem>
                                ))}
                            </Nav>
                        </Collapse>
                        <div className="d-none d-lg-block">
                            <Dropdown
                                isOpen={this.state.isOpenMainMenu}
                                toggle={this.fakeToggle}
                            >
                                <DropdownToggle
                                    tag="span"
                                    className="dropdown-toggler"
                                    onClick={this.toggleMainMenu}
                                >
                                    {this.props.t('menu')}

                                    <span className="dropdown-arrow ml-2">
                                        <span className="SVGInline">
                                            <DownArrow />
                                        </span>
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {mainNavigationLogout.map(node => (
                                        <DropdownItem key={node.path} tag="div">
                                            {this.renderNavLink(node)}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Container>
                </Navbar>
            </section>
        );
    };

    render() {
        const theRoute = routes.find(
            route => route.path === this.props.match.path
        );

        if (theRoute.menuType === MENU.SIMPLE) {
            return <SimpleMenu />;
        }
        if (global.localStorage.getItem('token') !== null) {
            return this.renderMenuLogin();
        }
        return this.renderMenuLogout();
    }
}

export default withApollo(withRouter(withLocale(MenuLayout)));
