import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import phoneIcon from 'assets/icons/phone_icon.svg';
import deliveryIcon from 'assets/icons/delivery_icon.svg';
import emailIcon from 'assets/icons/email_icon.svg';
import { PATHS } from 'config/paths';

export class ShoppingCartThankYouServiceActivationComponent extends React.Component {
    render() {
        const {
            containsPhysicalProduct,
            isCod,
            isDietInPayment,
            isTrainingInPayment,
        } = this.props;
        return (
            <React.Fragment>
                {containsPhysicalProduct || isCod ? (
                    <section data-test="thank-you/how-to-activate">
                        <Container>
                            {isCod &&
                            (isDietInPayment || isTrainingInPayment) ? (
                                <React.Fragment>
                                    <Row>
                                        <Col md="7">
                                            <h2 className="mb-5">
                                                {this.props.t(
                                                    'thank-you/how-to-activate-the-service'
                                                )}
                                            </h2>
                                            <div className="d-flex mb-5">
                                                <img
                                                    src={emailIcon}
                                                    className="mr-5"
                                                    alt=""
                                                />
                                                <p>
                                                    {this.props.t(
                                                        'thank-you/step1'
                                                    )}
                                                </p>
                                            </div>
                                            <div className="d-flex mb-5">
                                                <img
                                                    src={deliveryIcon}
                                                    className="mr-5"
                                                    alt=""
                                                />
                                                <p>
                                                    {this.props.t(
                                                        'thank-you/step2'
                                                    )}
                                                </p>
                                            </div>
                                            <div className="d-flex mb-5">
                                                <img
                                                    src={phoneIcon}
                                                    className="mr-5"
                                                    alt=""
                                                />
                                                <p>
                                                    {this.props.t(
                                                        'thank-you/step3'
                                                    )}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col
                                            md={{ size: 4, offset: 1 }}
                                            data-test="thank-you/flyer"
                                        >
                                            <p className="text-center">
                                                <strong>
                                                    {this.props.t(
                                                        'thank-you/activation-code-and-instruction'
                                                    )}
                                                </strong>
                                            </p>

                                            <div className="flyer">
                                                <header>
                                                    {this.props.t(
                                                        'thank-you/flyer-header'
                                                    )}
                                                </header>
                                                <main>
                                                    <p>
                                                        {this.props.t(
                                                            'thank-you/flyer-content1'
                                                        )}
                                                    </p>
                                                    <p>
                                                        {this.props.t(
                                                            'thank-you/flyer-content2'
                                                        )}
                                                    </p>
                                                    <br />
                                                    <h3 className="text-center">
                                                        {this.props.t(
                                                            'thank-you/flyer-content3'
                                                        )}
                                                    </h3>
                                                </main>
                                            </div>

                                            <p className="text-center">
                                                {this.props.t(
                                                    'thank-you/flyer-footer1'
                                                )}
                                                {this.props.t(
                                                    'thank-you/flyer-footer1'
                                                )}
                                                <a href="/aktivace">
                                                    dietaoxy.cz/aktivace
                                                </a>
                                                {this.props.t(
                                                    'thank-you/flyer-footer2'
                                                )}
                                            </p>

                                            <p className="code">
                                                <strong>xxx-xxx-xxx</strong>
                                            </p>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Row>
                                        <Col md={{ size: 8, offset: 2 }}>
                                            <h2 className="mb-5 text-center">
                                                {this.props.t(
                                                    'thank-you/order-is-processed'
                                                )}
                                            </h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4" className="text-center">
                                            <img
                                                src={emailIcon}
                                                className="mb-3"
                                                alt=""
                                            />
                                            <p>
                                                {this.props.t(
                                                    'thank-you/step1'
                                                )}
                                            </p>
                                        </Col>
                                        <Col md="4" className="text-center">
                                            <img
                                                src={deliveryIcon}
                                                className="mb-3"
                                                alt=""
                                            />
                                            <p>
                                                {this.props.t(
                                                    'thank-you/step2'
                                                )}
                                            </p>
                                        </Col>
                                        <Col md="4" className="text-center">
                                            <img
                                                src={phoneIcon}
                                                className="mb-3"
                                                alt=""
                                            />
                                            <p>
                                                {this.props.t(
                                                    'thank-you/step3'
                                                )}
                                            </p>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-4">
                                        <Button
                                            tag={Link}
                                            to={PATHS.EXTERNAL.HOME}
                                            color="primary"
                                        >
                                            {this.props.t(
                                                'thank-you/go-to-home'
                                            )}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </Container>
                    </section>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}

ShoppingCartThankYouServiceActivationComponent.propTypes = {
    t: PropTypes.func.isRequired,
    isDietInPayment: PropTypes.bool.isRequired,
    isTrainingInPayment: PropTypes.bool.isRequired,
    isCod: PropTypes.bool.isRequired,
    containsPhysicalProduct: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartThankYouServiceActivationComponent);
