import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { createDateObject } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col, Alert } from 'reactstrap';
import HpbaMarkdown from 'Content/utils/HpbaMarkdown';
import ContentPlaceholder from 'Content/Index/ContentPlaceholder';
import BackLink from 'Layout/BackLink';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import person from 'assets/icons/person.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import { PATH_KNOWLEDGE } from '../paths';

class Article extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        article: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            lead: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            photoUrl: PropTypes.string.isRequired,
            summary: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            author: PropTypes.shape({
                name: PropTypes.string,
                isFemale: PropTypes.string,
                description: PropTypes.string,
                avatar: PropTypes.string,
            }).isRequired,
        }).isRequired,
        accessTo: PropTypes.string,
        trackFirebaseEvent: PropTypes.func.isRequired,
        loadSaleProduct: PropTypes.func.isRequired,
        loadContent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        accessTo: undefined,
    };

    componentDidMount() {
        if (this.props.article) {
            this.props.trackFirebaseEvent('Article_pageview', {
                title: this.props.article.title,
            });
        }
    }

    renderLead = () => {
        if (this.props.article.lead.markdown !== '') {
            return (
                <React.Fragment>
                    <div className="article-lead">
                        <HpbaMarkdown
                            content={this.props.article.lead.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                        />
                    </div>
                </React.Fragment>
            );
        }

        return '';
    };

    renderContent = hasAccess => {
        if (this.props.article.content.markdown !== '' && hasAccess) {
            return (
                <React.Fragment>
                    <div className="cms-content">
                        <HpbaMarkdown
                            content={this.props.article.content.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                            loadContent={this.props.loadContent}
                        />
                        <div className="clearfix" />
                    </div>
                </React.Fragment>
            );
        }

        return '';
    };

    renderSummary = hasAccess => {
        if (this.props.article.summary.markdown !== '' && hasAccess) {
            return (
                <React.Fragment>
                    <div className="article-summary">
                        <HpbaMarkdown
                            content={this.props.article.summary.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                        />
                    </div>
                    <hr />
                </React.Fragment>
            );
        }
        return '';
    };

    render() {
        let hasAccess = true;

        if (this.props.accessTo) {
            const now = new Date();
            const today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );
            const date = createDateObject(this.props.accessTo);
            if (date >= today) {
                hasAccess = true;
            }
        }

        if (this.props.article) {
            return (
                <Layout page="cms-article">
                    <div className="bg-white">
                        <header>
                            <Container className="text-center">
                                <h1>{this.props.article.title}</h1>
                            </Container>
                        </header>
                        <section className="pt-0">
                            <Container>
                                <div className="article-img">
                                    <LazyLoadImage
                                        className="img-fluid"
                                        effect="blur"
                                        src={this.props.article.photoUrl}
                                    />
                                </div>
                                <Row>
                                    <Col md={{ size: 10, offset: 1 }}>
                                        <div className="author mb-4">
                                            <img src={person} alt="" />
                                            {this.props.t(
                                                'knowledge/author'
                                            )}: {this.props.article.author.name}
                                        </div>
                                        {this.renderLead()}
                                        {this.renderContent(hasAccess)}
                                        {this.renderSummary(hasAccess)}

                                        {!hasAccess ? (
                                            <Alert color="danger">
                                                {this.props.t(
                                                    'knowledge/no-access'
                                                )}
                                            </Alert>
                                        ) : (
                                            ''
                                        )}

                                        <div className="author-info my-4">
                                            <h4>
                                                {this.props.article.author
                                                    .isFemale
                                                    ? this.props.t(
                                                          'knowledge/about-author-female'
                                                      )
                                                    : this.props.t(
                                                          'knowledge/about-author-male'
                                                      )}
                                            </h4>
                                            <Row className="align-items-center">
                                                <Col xs="12" md="2">
                                                    <img
                                                        src={
                                                            this.props.article
                                                                .author.avatar
                                                        }
                                                        className="rounded-circle img-fluid mb-3"
                                                        alt=""
                                                    />
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <p>
                                                        {
                                                            this.props.article
                                                                .author
                                                                .description
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-3">
                                    <hr className="mb-0" />
                                    <BackLink to={PATH_KNOWLEDGE}>
                                        {this.props.t(
                                            'knowledge/back-to-article-list'
                                        )}
                                    </BackLink>
                                    <hr className="mt-0" />
                                </div>
                            </Container>
                        </section>
                    </div>
                </Layout>
            );
        }
        return <Redirect to={PATHS.ERROR_404} />;
    }
}

export default withLoading(
    withFirebase(withLocale(Article)),
    ContentPlaceholder
);
